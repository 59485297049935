import { useCallback, useEffect, useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';

type UseUnloadWarningRes = {
  safeNavigate: (route: string) => void;
};

export const useUnloadWarning = (): UseUnloadWarningRes => {
  const navigate = useNavigate();

  const [shouldProtectFromNavigate, setShouldProtectFromNavigate] = useState<boolean>(true);
  const [pendingNavigation, setPendingNavigation] = useState<string | null>(null);
  const blocker = useBlocker(shouldProtectFromNavigate);

  useEffect(() => {
    if (!shouldProtectFromNavigate) {
      return;
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = ''; // Required for Chrome
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldProtectFromNavigate]);

  useEffect(() => {
    if (!shouldProtectFromNavigate) {
      return;
    }

    if (blocker.state === 'blocked') {
      const confirmLeave = window.confirm(
        'Are you sure you want to leave? Unsaved changes will be lost.'
      );
      if (confirmLeave) {
        // Allow navigation
        blocker.proceed();
      } else {
        // Cancel navigation
        blocker.reset();
      }
    }
  }, [blocker, shouldProtectFromNavigate]);

  useEffect(() => {
    if (pendingNavigation && !shouldProtectFromNavigate) {
      navigate(pendingNavigation);
      setPendingNavigation(null);
    }
  }, [pendingNavigation, shouldProtectFromNavigate, navigate, setShouldProtectFromNavigate]);

  const safeNavigate = useCallback(
    (route: string) => {
      setShouldProtectFromNavigate(false);
      setPendingNavigation(route);
    },
    [setShouldProtectFromNavigate, setPendingNavigation]
  );

  return { safeNavigate };
};
