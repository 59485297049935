import { getCurrentUsedBins } from '../../getCurrentUsedBins';
import { ActionArgs, State } from '../../types';

export const scanBin = ({
  action,
  state,
  packingMode,
  dispatchFns
}: Pick<
  ActionArgs<'awaiting_picklist_bin', 'scan_bin', 'batch'>,
  'action' | 'state' | 'packingMode' | 'dispatchFns'
>): State => {
  const currentUsedBins = getCurrentUsedBins(packingMode.packingSessions);
  if (currentUsedBins.has(action.binCode)) {
    throw new Error('Bin code is already in use');
  }
  dispatchFns.addPackingSession(state.context.packingSession, action.binCode);
  return { state: 'picking', context: {} };
};
