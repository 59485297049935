import { Button, Divider, Paper, Typography } from '@mui/material';
import { useCallback } from 'react';

export const OrderSummaryCard = ({
  orderId,
  binCode,
  greenBagCount,
  coldBagCount,
  onClick
}: {
  orderId: number;
  binCode: string;
  greenBagCount: number;
  coldBagCount: number;
  onClick: (orderId: number) => void;
}) => {
  const handleDelete = useCallback(
    (orderId: number) => {
      if (confirm('Are you sure you want to remove this order from the packing session?')) {
        onClick(orderId);
      }
    },
    [onClick]
  );

  return (
    <Paper
      elevation={0}
      sx={{
        p: 1,
        border: '2px solid',
        borderColor: binCode,
        display: 'flex',
        flexDirection: 'row',
        gap: 1
      }}
    >
      <div>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          {orderId}
        </Typography>
      </div>
      <Divider orientation="vertical" flexItem />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Typography variant="body1"># Green Bags</Typography>
        <Typography variant="body1"># Cold Bags</Typography>
      </div>
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          {greenBagCount}
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          {coldBagCount}
        </Typography>
      </div>
      <Button
        size="small"
        color="error"
        variant="outlined"
        style={{ fontWeight: 'bold' }}
        onClick={() => handleDelete(orderId)}
      >
        Remove
      </Button>
    </Paper>
  );
};
