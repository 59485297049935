import { DateTime } from 'luxon';
import { BagType } from '../../constants/BagType';

export enum ProductPackingState {
  UNPICKED = 'unpicked',
  PACKED = 'packed',
  SKIPPED = 'skipped'
}

export type Product = {
  parentProductId: number;
  sellableEntityVariationId: number;
  hashedOrderProductId: string;
  productName: string;
  warehouseLocation?: string | null;
  isAmPacked?: boolean | null;
  isOos: boolean;
  backstockLocation: string;
  preppedInventoryQuantity: string;
  pickSortOrder: string;
  doesRequireColdChainBag: boolean;
};

export type ProductPackingStateObj =
  | { state: ProductPackingState.UNPICKED }
  | { state: ProductPackingState.PACKED; assetId?: string; timestamp: DateTime }
  | { state: ProductPackingState.SKIPPED; timestamp: DateTime };

export type PackedProduct = Product & ProductPackingStateObj;

export type ScannedBag = { assetId: string; bagType: BagType };

export type OrderPackingState = {
  userId: number;
  orderId: number;
  orderIntentId: number;
  deliveryDate: Date;
  sessionId: number;
  batchPackSessionId: number;
  pickingProducts: Record<string, PackedProduct>;
  bags: ScannedBag[];
};

export type OrderPackingStateWithBin = OrderPackingState & { binCode: string };

type OrderId = number;
export type PackingSession = Record<OrderId, OrderPackingStateWithBin>;

export type DispatchFns = {
  addPackingSession: (session: OrderPackingState, binCode: string) => void;
  clearPackingSessions: () => void;
  skipProduct: (args: { orderId: number; hashedOrderProductId: string }) => void;
  unskipProduct: (args: { orderId: number; hashedOrderProductId: string }) => void;
  packProduct: (args: { orderId: number; parentProductId: number; assetId?: string }) => void;
  packBag: (args: { orderId: number; assetId: string; bagType: BagType }) => void;
  clearProduct: (args: { orderId: number; hashedOrderProductId: string }) => void;
  removePackingSession: (args: { orderId: number }) => void;
};

export type EventCallback = (data: {
  event: 'product-packed';
  payload: {
    hashedOrderProductId: string;
  };
}) => void;

export type PickingSessionType = 'single' | 'batch';

export type PackingModeArgs =
  | {
      type: 'single';
      packingSession: OrderPackingStateWithBin;
    }
  | {
      type: 'batch';
      packingSessions: PackingSession;
    };

export type PackingSessionContextValue = {
  packingSessionType: PickingSessionType;
  setPickingSessionType: (type: PickingSessionType) => void;
  packingMode: PackingModeArgs;
  isBatchPacking: boolean;
  dispatchFns: DispatchFns;
  getOrderIdFromBinCode: (binCode: string) => number;
  getCountProductRemainingToPackByBinCode: (args: {
    binCode: string;
    parentProductId: number;
  }) => number;
  subscribe: (callback: EventCallback) => () => void;
  hasStartedPackingProducts: boolean;
};

export type ClearPackingSessionsFn = () => PackingSession;
export type AddPackingSessionFn = (args: {
  state: PackingSession;
  packSession: OrderPackingState;
  binCode: string;
}) => PackingSession;
export type SkipProductFn = (args: {
  state: PackingSession;
  orderId: number;
  hashedOrderProductId: string;
}) => PackingSession;
export type UnskipProductFn = (args: {
  state: PackingSession;
  orderId: number;
  hashedOrderProductId: string;
}) => PackingSession;
export type PackProductFn = (args: {
  state: PackingSession;
  orderId: number;
  parentProductId: number;
  assetId?: string;
}) => {
  packSessions: PackingSession;
  affectedProduct: PackedProduct;
};
export type PackBagFn = (args: {
  state: PackingSession;
  orderId: number;
  assetId: string;
  bagType: BagType;
}) => PackingSession;
export type ClearProductFn = (args: {
  state: PackingSession;
  orderId: number;
  hashedOrderProductId: string;
}) => PackingSession;
export type RemovePackingSessionFn = (args: {
  state: PackingSession;
  orderId: number;
}) => PackingSession;
