import { useCallback } from 'react';
import { useCreatePackingSession } from '../../../apis/hooks/useCreatePackingSession';
import { ScanData } from './useDecodeScan';
import { PickingAction } from './usePickingMachine/types';

type PickableProduct = {
  parentProductId: number;
  binCode: string;
};

type ProcessPickingStateMachineFromScanProps = {
  scan: ScanData['success'] extends true ? ScanData : never;
  processPickAction: (action: PickingAction) => void;
  pickableProducts: PickableProduct[];
  packedAssetIds: string[];
  batchPackSessionId?: number;
};

/**
 * This hook is used to process backend returned scan data into a state machine event.
 *
 * @param scan The scan data to process.
 * @param processPickAction The function to process the state machine event.
 * @param pickableProducts Products that can be scanned and is used to determine which bin to scan for batch packing.
 * @returns A function that will process an event in the state machine from a scan.
 */
export const useProcessPickingStateMachineFromScan = () => {
  const { createPackingSession } = useCreatePackingSession();

  const processStateMachineFromScan = useCallback(
    async ({
      scan,
      processPickAction,
      pickableProducts,
      packedAssetIds,
      batchPackSessionId
    }: ProcessPickingStateMachineFromScanProps): Promise<void> => {
      switch (scan.type) {
        case 'picklist': {
          const packingSession = await createPackingSession({
            ...scan.picklist,
            isBatchPacking: !!batchPackSessionId,
            existingPackingSessionId: batchPackSessionId
          });
          return processPickAction({
            type: 'scan_picklist',
            packingSession
          });
        }
        case 'bin': {
          return processPickAction({
            type: 'scan_bin',
            binCode: scan.binCode
          });
        }
        case 'product': {
          const productToScan = pickableProducts.find(
            (product) => product.parentProductId === scan.parentProductId
          );

          if (!productToScan) {
            throw new Error('Product not found in picking list');
          }

          if (!!scan.assetId) {
            const productAlreadyPacked = packedAssetIds.includes(scan.assetId);

            if (productAlreadyPacked) {
              throw new Error('Product already packed');
            }
          }

          return processPickAction({
            type: 'scan_product' as const,
            assetId: scan.assetId,
            parentProductId: scan.parentProductId,
            binCode: productToScan.binCode
          });
        }
        case 'bag':
          if (!!scan.assetId) {
            const bagAlreadyPacked = packedAssetIds.includes(scan.assetId);

            if (bagAlreadyPacked) {
              throw new Error('Bag already packed');
            }
          }

          return processPickAction({
            type: 'scan_bag' as const,
            assetId: scan.assetId,
            bagType: scan.bagType
          });
        default:
          throw new Error('Invalid scan type');
      }
    },
    [createPackingSession]
  );

  return { processStateMachineFromScan };
};
