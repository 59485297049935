import { useCallback, useState } from 'react';
import { useResolvePickScan } from '../../../apis/hooks/useResolvePickScan';
import { BagType } from '../../../constants/BagType';
import { BagType as BagTypeGql, ScannedCodeType } from '../../../gql/graphql';

type ProductScanResult = {
  success: true;
  type: 'product';
  parentProductId: number;
  assetId?: string;
};

type BagScanResult = {
  success: true;
  type: 'bag';
  assetId: string;
  bagType: BagType;
};

type BinScanResult = {
  success: true;
  type: 'bin';
  binCode: string;
};

type PicklistProduct = {
  parentProductId: number;
  sellableEntityVariationId: number;
  hashedOrderProductId: string;
  productName: string;
  warehouseLocation?: string | null;
  isAmPacked?: boolean | null;

  // TODO: Currently, the OOS process marks a products with a given ID as OOS across all orders. Future improvements may include handling partial OOS scenarios
  isOos: boolean;
  backstockLocation: string;
  preppedInventoryQuantity: string;
  pickSortOrder: string;
  doesRequireColdChainBag: boolean;
};

export type Picklist = {
  userId: number;
  orderId: number;
  orderIntentId: number;
  deliveryDate: Date;
  picklist: PicklistProduct[];
};

type PicklistScanResult = {
  success: true;
  type: 'picklist';
  picklist: Picklist;
};

type ErrorScanResult = {
  success: false;
  message: string;
};

export type ScanData = ProductScanResult | BagScanResult | BinScanResult | PicklistScanResult;
type ScanDataResponse = ScanData | ErrorScanResult;

const convertBagType = (bagType: BagTypeGql): BagType => {
  switch (bagType) {
    case BagTypeGql.Green:
      return 'green';
    case BagTypeGql.Cold:
      return 'cold';
    default:
      throw new Error('Invalid bag type');
  }
};

export const useDecodeScan = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { resolvePickScan } = useResolvePickScan();

  const getDecodedScan = useCallback(
    async ({ scanResult }: { scanResult: string }): Promise<ScanDataResponse> => {
      // Scan in progress
      if (loading) {
        return {
          success: false,
          message: 'Scan in progress'
        };
      }

      setLoading(true);

      try {
        const resolvePickScanResponse = await resolvePickScan(scanResult);
        if (!resolvePickScanResponse.success) {
          return {
            success: false,
            message: resolvePickScanResponse.message
          };
        }

        const { scanResponse } = resolvePickScanResponse;
        const scanType = scanResponse.__typename;

        if (scanType === 'BagScanType') {
          return {
            success: true,
            type: 'bag',
            assetId: scanResponse.parsed_scan_value,
            bagType: convertBagType(scanResponse.bag_type)
          };
        }

        if (scanType === 'ProductScanTypeV2') {
          const isReusableContainer =
            scanResponse.scanned_code_type === ScannedCodeType.ReusableContainer;
          const assetId = isReusableContainer ? scanResponse.parsed_scan_value : undefined;
          return {
            success: true,
            type: 'product',
            parentProductId: scanResponse.parent_product_id,
            assetId
          };
        }

        if (scanType === 'BinScanType') {
          return {
            success: true,
            type: 'bin',
            binCode: scanResponse.bin_code
          };
        }

        if (scanType === 'PicklistScanType') {
          return {
            success: true,
            type: 'picklist',
            picklist: {
              userId: scanResponse.picklist.user_id,
              orderId: scanResponse.picklist.order_id,
              orderIntentId: scanResponse.picklist.order_intent_id,
              deliveryDate: scanResponse.picklist.delivery_date,
              picklist: scanResponse.picklist.picklist.map((product) => ({
                parentProductId: product.parent_product_id,
                sellableEntityVariationId: product.product_id,
                hashedOrderProductId: product.hashed_order_product_id,
                productName: product.product_name,
                warehouseLocation: product.warehouse_location,
                isAmPacked: product.is_am_packed,
                isOos: product.is_oos,
                backstockLocation: product.backstock_location,
                preppedInventoryQuantity: product.prepped_inventory_quantity,
                pickSortOrder: product.pick_sort_order,
                doesRequireColdChainBag: product.does_require_cold_chain_bag
              }))
            }
          };
        }

        return {
          success: false,
          message: 'Invalid scan type'
        };
      } catch (err: any) {
        return {
          success: false,
          message: err?.message
        };
      } finally {
        setLoading(false);
      }
    },
    [loading, resolvePickScan, setLoading]
  );

  return { getDecodedScan, loading };
};
