type AssetObject = {
  assetId?: string;
};

type IsAlreadyScannedAssetArgs = {
  alreadyScannedAssets: AssetObject[];
  scannedAsset?: string;
  pendingScannedAssets?: AssetObject[];
};

export const isAlreadyScannedAsset = ({
  alreadyScannedAssets,
  scannedAsset,
  pendingScannedAssets = []
}: IsAlreadyScannedAssetArgs) => {
  const allScannedAssets = [...alreadyScannedAssets, ...pendingScannedAssets];
  return allScannedAssets.some((scan) => scan.assetId === scannedAsset);
};
