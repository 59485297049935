import { ActionArgs, State } from '../../types';
import { doesOrderRequireColdChainBagScan } from '../utils/isColdChainProduct';

export const scanBin = ({
  action,
  state,
  addAlert,
  getOrderIdFromBinCode,
  dispatchFns,
  packingMode
}: Pick<
  ActionArgs<'awaiting_product_bin', 'scan_bin', 'batch'>,
  'action' | 'state' | 'addAlert' | 'getOrderIdFromBinCode' | 'dispatchFns' | 'packingMode'
>): State => {
  // If the bin code is different show an error and stay in the same state
  if (state.context.binCode && state.context.binCode !== action.binCode) {
    addAlert({
      severity: 'error',
      title: 'Invalid Bin Code',
      message: 'Please scan the correct bin code.'
    });
    return state;
  }

  const orderId = getOrderIdFromBinCode(action.binCode);
  for (const stateContext of state.context.pendingProductScans) {
    dispatchFns.packProduct({
      orderId,
      parentProductId: state.context.parentProductId,
      assetId: stateContext.assetId
    });
  }

  if (
    doesOrderRequireColdChainBagScan({
      parentProductId: state.context.parentProductId,
      packingSession: packingMode.packingSessions[orderId]
    })
  ) {
    return {
      state: 'awaiting_cold_chain_bag',
      context: {}
    };
  }

  return { state: 'picking', context: {} };
};
