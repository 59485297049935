import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Button, Card, CardContent, ClickAwayListener, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useRef } from 'react';
import { ProductPackingState } from '../../../../contexts/PackingSessionContext/types';
import { ProductCardActions, ProductCardData } from '../types';
import styles from './ProductCard.module.css';
import { getIdForProductCard } from './getIdForProductCard';

type ProductCardProps = ProductCardData & ProductCardActions;

const ActionButton: React.FC<{
  onClick: () => void;
  label: string;
}> = ({ onClick, label }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current && document.activeElement === buttonRef.current) {
      buttonRef.current.blur();
    }
  }, [buttonRef.current, document.activeElement]);

  return (
    <Button
      onClick={onClick}
      size="small"
      color="primary"
      variant="outlined"
      sx={{ fontWeight: 'bold' }}>
      {label}
    </Button>
  );
};

export const ProductCard: React.FC<ProductCardProps> = ({
  hashedOrderProductId,
  name,
  binCode,
  state,
  location,
  orderId,
  isOos,
  sellableEntityVariantId,
  backstockLocation,
  preppedInventoryQuantity,
  onSkip,
  onUnskip,
  onClear
}) => {
  const [toolTipOpen, setToolTipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setToolTipOpen(false);
  };

  const handleTooltipOpen = () => {
    setToolTipOpen(true);
  };

  const handleAction = (action: typeof onSkip | typeof onUnskip | typeof onClear) => {
    action({ orderId, hashedOrderProductId });
  };

  const cardClassName = useMemo(() => {
    return `${styles.productCard} ${
      state === ProductPackingState.PACKED
        ? styles.packed
        : state === ProductPackingState.SKIPPED
        ? styles.skipped
        : ''
    }`;
  }, [state]);

  const stateIcon = useMemo(() => {
    switch (state) {
      case ProductPackingState.PACKED:
        return <CheckCircleIcon color="success" />;
      case ProductPackingState.SKIPPED:
        return <PauseCircleIcon color="warning" />;
      case ProductPackingState.UNPICKED:
      default:
        return <RadioButtonUncheckedIcon color="disabled" />;
    }
  }, [state]);

  const actionButton = useMemo(() => {
    if (state === ProductPackingState.SKIPPED) {
      return <ActionButton onClick={() => handleAction(onUnskip)} label="Unskip" />;
    }

    if (state === ProductPackingState.PACKED) {
      return <ActionButton onClick={() => handleAction(onClear)} label="Clear" />;
    }

    if (isOos) {
      return (
        <Typography
          variant="button"
          className={styles.outOfStock}
          sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
          OOS
        </Typography>
      );
    }

    return <ActionButton onClick={() => handleAction(onSkip)} label="Skip" />;
  }, [isOos, state, handleAction, onUnskip, onClear, onSkip]);

  return (
    <div id={getIdForProductCard({ hashedOrderProductId })}>
      <Card
        className={cardClassName}
        style={{
          ['--border-color' as string]: binCode
        }}>
        <CardContent className={styles.cardContent}>
          <Box className={styles.contentContainer}>
            <Box>{stateIcon}</Box>

            <Box className={styles.productInfo}>
              <Typography variant="body2" sx={{ fontWeight: 'bolder', fontSize: '20px' }}>
                {location}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '16px' }}>
                {name}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '16px' }}>
                {sellableEntityVariantId}
              </Typography>
            </Box>

            <Box className={styles.actionContainer}>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  title={
                    <Typography variant="body2">
                      Backstock: {backstockLocation}
                      <br />
                      Quantity: {preppedInventoryQuantity}
                    </Typography>
                  }
                  onClose={handleTooltipClose}
                  open={toolTipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="top-start"
                  arrow>
                  <Button onClick={handleTooltipOpen}>?</Button>
                </Tooltip>
              </ClickAwayListener>

              {actionButton}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};
