import { ScannedBag } from '../../../../../../contexts/PackingSessionContext/types';
import { PackingModeArgs } from '../../types';

export const getBagsFromPackingMode = (
  packingMode: PackingModeArgs['packingMode']
): ScannedBag[] => {
  if (packingMode.type === 'single') {
    return packingMode.packingSession.bags;
  }
  return Object.values(packingMode.packingSessions).flatMap((session) => session.bags);
};
