import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { OrderPackingStateWithBin } from '../../../contexts/PackingSessionContext/types';
import { OrderSummary } from './OrderSummary';
import { ProductList } from './ProductList';
import { ProductCardData } from './types';

interface PackingViewProps {
  packingSessions: Record<number, OrderPackingStateWithBin>;
  amProducts: ProductCardData[];
  pmProducts: ProductCardData[];
  onRemoveSession: (orderId: number) => void;
  onSkipProduct: (params: { orderId: number; hashedOrderProductId: string }) => void;
  onUnskipProduct: (params: { orderId: number; hashedOrderProductId: string }) => void;
  onClearProduct: (params: { orderId: number; hashedOrderProductId: string }) => void;
}

export function PackingView({
  packingSessions,
  amProducts,
  pmProducts,
  onRemoveSession,
  onSkipProduct,
  onUnskipProduct,
  onClearProduct
}: PackingViewProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 1, gap: 1, mb: '75px' }}>
      <OrderSummary packingSessions={packingSessions} onClick={onRemoveSession} />

      <Accordion
        defaultExpanded
        sx={(theme) => ({
          boxShadow: 'none',
          border: `2px solid ${theme.palette.divider}`,
          '&:before': {
            display: 'none'
          },
          '&.Mui-expanded': {
            margin: 0
          }
        })}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">PM Products ({pmProducts.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProductList
            products={pmProducts}
            onSkip={onSkipProduct}
            onUnskip={onUnskipProduct}
            onClear={onClearProduct}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        sx={(theme) => ({
          boxShadow: 'none',
          border: `2px solid ${theme.palette.divider}`,
          '&:before': {
            display: 'none'
          },
          '&.Mui-expanded': {
            margin: 0
          }
        })}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">AM Products ({amProducts.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProductList
            products={amProducts}
            onSkip={onSkipProduct}
            onUnskip={onUnskipProduct}
            onClear={onClearProduct}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
