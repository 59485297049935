import { getTransitionError } from '../../getTransitionError';
import { ActionsFn } from '../../types';
import { isActionType, isPackingSessionType, isState } from '../utils/typeValidators';
import { cancel } from './cancel';
import { scanBin } from './scanBin';
import { scanProduct } from './scanProduct';

export const awaitingProductBinActions: ActionsFn = (args) => {
  if (!isState(args, 'awaiting_product_bin')) {
    throw new Error('Invalid state, not "awaiting_product_bin"');
  }

  if (!isPackingSessionType(args, 'batch')) {
    throw new Error('Single packing is not supported in this state');
  }

  if (isActionType(args, 'scan_product')) {
    // Support scanning multiple products for a single order
    return scanProduct(args);
  } else if (isActionType(args, 'scan_bin')) {
    // Transact the pending product scans
    return scanBin(args);
  } else if (isActionType(args, 'cancel')) {
    return cancel(args);
  }

  throw getTransitionError(args.state, args.action.type);
};
