import { ActionArgs, State } from '../../types';
import { getBagsFromPackingMode } from '../utils/getBagsFromPackingMode';
import { isAlreadyScannedAsset } from '../utils/isAlreadyScannedAsset';

export const scanBag = ({
  action,
  state,
  addAlert,
  dispatchFns,
  packingMode
}: Pick<
  ActionArgs<'picking', 'scan_bag'>,
  'action' | 'state' | 'addAlert' | 'dispatchFns' | 'packingMode'
>): State => {
  // Check if the bag has already been scanned
  if (
    !!action.assetId &&
    isAlreadyScannedAsset({
      alreadyScannedAssets: getBagsFromPackingMode(packingMode),
      scannedAsset: action.assetId
    })
  ) {
    addAlert({
      severity: 'error',
      title: 'Bag already scanned',
      message: 'Please scan a different bag.'
    });
    return state;
  }

  if (packingMode.type === 'single') {
    dispatchFns.packBag({
      orderId: packingMode.packingSession.orderId,
      assetId: action.assetId,
      bagType: action.bagType
    });
    return {
      state: state.state,
      context: {}
    };
  } else if (packingMode.type === 'batch') {
    return {
      state: 'awaiting_bag_bin',
      context: {
        pendingBagScans: [
          {
            assetId: action.assetId,
            bagType: action.bagType
          }
        ]
      }
    };
  }
  throw new Error('Invalid packing session type');
};
