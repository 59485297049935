import { Box } from '@mui/material';
import React from 'react';
import { ProductCard } from './ProductCard';
import { ProductCardActions, ProductCardData } from './types';

type ProductListProps = {
  products: ProductCardData[];
} & ProductCardActions;

export const ProductList: React.FC<ProductListProps> = ({
  products,
  onSkip,
  onUnskip,
  onClear
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.25,
        maxWidth: '100%',
        margin: '0 auto',
        overflowY: 'auto'
      }}
    >
      {products.map((product) => (
        <ProductCard
          key={product.hashedOrderProductId}
          {...product}
          onSkip={onSkip}
          onUnskip={onUnskip}
          onClear={onClear}
        />
      ))}
    </Box>
  );
};
