import { ProductPackingState } from '../../../../../../contexts/PackingSessionContext/types';
import { ActionArgs, State } from '../../types';
import { getProductsFromPackingMode } from '../utils/getProductsFromPackingMode';
import { isAlreadyScannedAsset } from '../utils/isAlreadyScannedAsset';
import { doesOrderRequireColdChainBagScan } from '../utils/isColdChainProduct';

export const scanProduct = ({
  action,
  state,
  addAlert,
  dispatchFns,
  packingMode
}: Pick<
  ActionArgs<'picking', 'scan_product'>,
  'action' | 'state' | 'addAlert' | 'dispatchFns' | 'packingMode'
>): State => {
  const alreadyScannedProducts = getProductsFromPackingMode(packingMode).filter(
    (
      product
    ): product is typeof product & { state: ProductPackingState.PACKED; assetId?: string } =>
      product.state === ProductPackingState.PACKED
  );

  // Check if the product asset has already been scanned
  if (
    !!action.assetId &&
    isAlreadyScannedAsset({
      alreadyScannedAssets: alreadyScannedProducts.map((product) => ({
        assetId: product.assetId
      })),
      scannedAsset: action.assetId
    })
  ) {
    addAlert({
      severity: 'error',
      title: 'Product already scanned',
      message: 'Please scan a different product.'
    });
    return state;
  }

  if (packingMode.type === 'single') {
    dispatchFns.packProduct({
      orderId: packingMode.packingSession.orderId,
      parentProductId: action.parentProductId,
      assetId: action.assetId
    });

    if (
      doesOrderRequireColdChainBagScan({
        parentProductId: action.parentProductId,
        packingSession: packingMode.packingSession
      })
    ) {
      return {
        state: 'awaiting_cold_chain_bag',
        context: {}
      };
    }

    return {
      state: state.state,
      context: {}
    };
  } else if (packingMode.type === 'batch') {
    return {
      state: 'awaiting_product_bin',
      context: {
        pendingProductScans: [
          {
            assetId: action.assetId
          }
        ],
        binCode: action.binCode,
        parentProductId: action.parentProductId
      }
    };
  }
  throw new Error('Invalid packing session type');
};
