import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { RLNrc } from '../constants/RLNrc';

interface LoginContextValue {
  authUser: AuthUser | null;
  setAuthUser: (authUser: AuthUser | null) => void;
  isLoggedIn: boolean;
}

const LoginContext = createContext<LoginContextValue>({
  authUser: null,
  setAuthUser: () => {},
  isLoggedIn: false
});

export const useLoginContext = () => useContext(LoginContext);

type AuthUser = {
  email: string;
  nrc: RLNrc;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);
  const loggedIn = useMemo(() => !!authUser, [authUser]);

  return (
    <LoginContext.Provider value={{ authUser, setAuthUser, isLoggedIn: loggedIn }}>
      {children}
    </LoginContext.Provider>
  );
};
