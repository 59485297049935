import { getTransitionError } from '../../getTransitionError';
import { ActionsFn } from '../../types';
import { isActionType, isPackingSessionType, isState } from '../utils/typeValidators';
import { cancel } from './cancel';
import { scanBag } from './scanBag';
import { scanBin } from './scanBin';

export const awaitingBagBinActions: ActionsFn = (args) => {
  if (!isState(args, 'awaiting_bag_bin')) {
    throw new Error('Invalid state, not "awaiting_bag_bin"');
  }

  if (!isPackingSessionType(args, 'batch')) {
    throw new Error('Single packing is not supported in this state');
  }

  if (isActionType(args, 'scan_bag')) {
    // Support scanning multiple bags for a single order
    return scanBag(args);
  } else if (isActionType(args, 'scan_bin')) {
    // Transact the pending bag scans
    return scanBin(args);
  } else if (isActionType(args, 'cancel')) {
    return cancel(args);
  }

  throw getTransitionError(args.state, args.action.type);
};
