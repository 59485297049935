import { ActionArgs, State } from '../../types';
import { getBagsFromPackingMode } from '../utils/getBagsFromPackingMode';
import { isAlreadyScannedAsset } from '../utils/isAlreadyScannedAsset';

export const scanBag = ({
  action,
  state,
  addAlert,
  packingMode
}: Pick<
  ActionArgs<'awaiting_bag_bin', 'scan_bag', 'batch'>,
  'action' | 'state' | 'addAlert' | 'packingMode'
>): State => {
  const alreadyScannedBags = getBagsFromPackingMode(packingMode);
  if (
    isAlreadyScannedAsset({
      alreadyScannedAssets: alreadyScannedBags,
      pendingScannedAssets: state.context.pendingBagScans,
      scannedAsset: action.assetId
    })
  ) {
    addAlert({
      severity: 'error',
      title: 'Bag already scanned',
      message: 'Please scan a different bag.'
    });
    return state;
  }

  return {
    state: state.state,
    context: {
      ...state.context,
      pendingBagScans: [
        ...state.context.pendingBagScans,
        { assetId: action.assetId, bagType: action.bagType }
      ]
    }
  };
};
