import { OrderPackingStateWithBin } from '../../../contexts/PackingSessionContext/types';
import { naturalSortHyphenSplit } from './naturalSort';

export const getProductDisplayList = (
  packingSessions: Record<number, OrderPackingStateWithBin>,
  isAm: boolean
) => {
  return Object.values(packingSessions)
    .flatMap((session) =>
      Object.values(session.pickingProducts)
        .filter((product) => product.isAmPacked === isAm)
        .map((product) => ({
          parentProductId: product.parentProductId,
          hashedOrderProductId: product.hashedOrderProductId,
          name: product.productName,
          state: product.state,
          binCode: session.binCode,
          location: product.warehouseLocation,
          orderId: session.orderId,
          isOos: product.isOos,
          sellableEntityVariantId: product.sellableEntityVariationId,
          backstockLocation: product.backstockLocation,
          preppedInventoryQuantity: product.preppedInventoryQuantity,
          pickSortOrder: product.pickSortOrder
        }))
    )
    .sort((a, b) => naturalSortHyphenSplit(a.pickSortOrder || '', b.pickSortOrder || ''));
};
