import { Box } from '@mui/material';
import { OrderPackingStateWithBin } from '../../../../contexts/PackingSessionContext/types';
import { OrderSummaryCard } from './OrderSummaryCard';

export const OrderSummary = ({
  packingSessions,
  onClick
}: {
  packingSessions: Record<number, OrderPackingStateWithBin>;
  onClick: (orderId: number) => void;
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {Object.values(packingSessions).map((session) => (
        <OrderSummaryCard
          key={session.orderId}
          orderId={session.orderId}
          binCode={session.binCode}
          greenBagCount={session.bags.filter((bag) => bag.bagType === 'green').length}
          coldBagCount={session.bags.filter((bag) => bag.bagType === 'cold').length}
          onClick={onClick}
        />
      ))}
    </Box>
  );
};
