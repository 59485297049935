import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

type ColdChainBagScanModalArgs = {
  onClose?: () => void;
  openScan: () => void;
  onSkip: () => void;
};

export const ColdChainBagScanModal = (args: ColdChainBagScanModalArgs) => {
  return (
    <Dialog
      open={true}
      onClose={args.onClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
          minWidth: '280px',
          margin: '24px',
          position: 'relative',
          overflow: 'visible'
        }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '8px',
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          backgroundColor: 'transparent'
        }}
      />
      <DialogContent
        sx={{
          padding: '32px 20px',
          textAlign: 'center'
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
          Scan Cold Chain Bag
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '0 20px 20px' }}
      >
        <Button
          onClick={args.openScan}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ fontWeight: 'bold' }}
        >
          Scan
        </Button>
        <Button
          onClick={args.onSkip}
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ fontWeight: 'bold' }}
        >
          Skip
        </Button>
      </DialogActions>
    </Dialog>
  );
};
