import { ProductPackingState } from '../../../../../../contexts/PackingSessionContext/types';
import { ActionArgs, State } from '../../types';
import { getProductsFromPackingMode } from '../utils/getProductsFromPackingMode';
import { isAlreadyScannedAsset } from '../utils/isAlreadyScannedAsset';

export const scanProduct = ({
  action,
  state,
  addAlert,
  getOrderIdFromBinCode,
  packingMode
}: Pick<
  ActionArgs<'awaiting_product_bin', 'scan_product', 'batch'>,
  'action' | 'state' | 'addAlert' | 'getOrderIdFromBinCode' | 'packingMode'
>): State => {
  const alreadyScannedProducts = getProductsFromPackingMode(packingMode).filter(
    (
      product
    ): product is typeof product & { state: ProductPackingState.PACKED; assetId?: string } =>
      product.state === ProductPackingState.PACKED
  );

  // Check if the same product container has already been scanned
  if (
    !!action.assetId &&
    isAlreadyScannedAsset({
      alreadyScannedAssets: alreadyScannedProducts.map((product) => ({
        assetId: product.assetId
      })),
      pendingScannedAssets: state.context.pendingProductScans,
      scannedAsset: action.assetId
    })
  ) {
    addAlert({
      severity: 'error',
      title: 'Product already scanned',
      message: 'Please scan a different product.'
    });
    return state;
  }

  // Check if the bin code is correct (scanned product is for the same order)
  if (state.context.binCode && state.context.binCode !== action.binCode) {
    addAlert({
      severity: 'error',
      title: 'Incorrect product scan',
      message: 'Please scan the correct product.'
    });
    return state;
  }

  // Check if the order exists in the packing session
  const orderId = getOrderIdFromBinCode(action.binCode);
  const currentPackingSession = packingMode.packingSessions[orderId];
  if (!currentPackingSession) {
    addAlert({
      severity: 'error',
      title: 'Incorrect product scan',
      message: 'Please scan a different product.'
    });
    return state;
  }

  // Check if all products have been packed
  const productsLeftToPack = Object.values(currentPackingSession.pickingProducts).filter(
    (product) => product.state === 'unpicked' && product.parentProductId === action.parentProductId
  );
  if (productsLeftToPack.length - state.context.pendingProductScans.length <= 0) {
    addAlert({
      severity: 'error',
      title: 'All products already packed',
      message: 'Scan a bin to record the picked products.'
    });
    return state;
  }

  return {
    state: state.state,
    context: {
      ...state.context,
      pendingProductScans: [
        ...state.context.pendingProductScans,
        {
          assetId: action.assetId
        }
      ]
    }
  };
};
