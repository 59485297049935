import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { ResolvedPickScan } from '../../gql/graphql';
import { resolvePickScanGql } from '../graph/picklist';

type UseResolvePickScanResponse =
  | {
      success: true;
      scanResponse: ResolvedPickScan;
    }
  | {
      success: false;
      message: string;
    };

export const useResolvePickScan = () => {
  const [resolveScan] = useLazyQuery(resolvePickScanGql, {
    fetchPolicy: 'no-cache'
  });

  const resolvePickScan = useCallback(
    async (scanResult: string): Promise<UseResolvePickScanResponse> => {
      const response = await resolveScan({
        variables: {
          decoded_scan_value: scanResult
        }
      });

      const scanResponse = response.data?.resolvePickScan;
      if (!scanResponse) {
        return {
          success: false,
          message: response.error?.message || 'Unknown error'
        };
      }

      return {
        success: true,
        scanResponse
      };
    },
    [resolveScan]
  );

  return {
    resolvePickScan
  };
};
