import { useLazyQuery } from '@apollo/client';
import { getPickPackUserGql } from '../graph/admin';
import { useCallback } from 'react';

type GetPickPackUserResponse =
  | {
      success: true;
      email: string;
    }
  | {
      success: false;
      error: any;
    };

export const useGetPickPackUser = () => {
  const [getPickPackUserQuery] = useLazyQuery(getPickPackUserGql, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const fetchUser = useCallback(
    async ({ email }: { email: string }): Promise<GetPickPackUserResponse> => {
      try {
        const response = await getPickPackUserQuery({
          variables: {
            email
          }
        });

        if (!response.data?.getPickPackUser) {
          return {
            success: false,
            error: 'User not found'
          };
        }

        return {
          success: true,
          email: response.data.getPickPackUser.email
        };
      } catch (err) {
        return {
          success: false,
          error: err
        };
      }
    },
    [getPickPackUserQuery]
  );

  return {
    fetchUser
  };
};
