import { ApolloError, useQuery } from '@apollo/client';
import { listRlLocations } from '../graph/admin';
import { RlVendor } from '../../gql/graphql';

type RlLocation = {
  id: string;
  name: string;
  vendor: RlVendor;
};

type UseListRlLocations = {
  locations: RlLocation[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
};

export const useListRlLocations = (): UseListRlLocations => {
  const { data, loading, error, refetch } = useQuery(listRlLocations, {
    fetchPolicy: 'no-cache'
  });

  return {
    locations: data?.listRlLocations ?? [],
    loading,
    error,
    refetch
  };
};
