import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';

import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import apolloClient from './clients/ApolloClient';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

if (JSON.parse(process.env.REACT_APP_SENTRY_ENABLED || 'false')) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ['error']
      })
    ],
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
    release: process.env.REACT_APP_GITHUB_SHA,
    environment: process.env.REACT_APP_SENTRY_ENV
  });
} else {
  console.log('Sentry disabled.');
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <ApolloProvider client={apolloClient}>
    <App />
  </ApolloProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
try {
  serviceWorkerRegistration.unregister();
} catch (err) {
  console.log(err);
}

console.log('REACT_APP_GITHUB_SHA: ', process.env.REACT_APP_GITHUB_SHA);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
