import { useCallback, useState } from 'react';
import { useAlertContext } from '../../../../contexts/AlertContext';
import { usePackingSessionContext } from '../../../../contexts/PackingSessionContext';
import { awaitingBagBinActions } from './actions/awaitingBagBin';
import { awaitingColdChainBagActions } from './actions/awaitingColdChainBag';
import { awaitingPicklistBinActions } from './actions/awaitingPicklistBin';
import { awaitingProductBinActions } from './actions/awaitingProductBin';
import { pickingActions } from './actions/picking';
import { PickingAction, State } from './types';

export function usePickingMachine() {
  const { addAlert } = useAlertContext();
  const [state, setState] = useState<State>({
    state: 'picking',
    context: {}
  });
  const { packingMode, dispatchFns, getOrderIdFromBinCode, hasStartedPackingProducts } =
    usePackingSessionContext();

  const dispatch = useCallback(
    (action: PickingAction): void => {
      try {
        let _state: State;
        switch (state.state) {
          case 'picking':
            _state = pickingActions({
              state,
              action,
              addAlert,
              packingMode,
              dispatchFns,
              getOrderIdFromBinCode,
              hasStartedPackingProducts
            });
            break;
          case 'awaiting_picklist_bin':
            _state = awaitingPicklistBinActions({
              state,
              action,
              addAlert,
              packingMode,
              dispatchFns,
              getOrderIdFromBinCode,
              hasStartedPackingProducts
            });
            break;
          case 'awaiting_product_bin':
            _state = awaitingProductBinActions({
              state,
              action,
              addAlert,
              packingMode,
              dispatchFns,
              getOrderIdFromBinCode,
              hasStartedPackingProducts
            });
            break;
          case 'awaiting_bag_bin':
            _state = awaitingBagBinActions({
              state,
              action,
              addAlert,
              packingMode,
              dispatchFns,
              getOrderIdFromBinCode,
              hasStartedPackingProducts
            });
            break;
          case 'awaiting_cold_chain_bag':
            _state = awaitingColdChainBagActions({
              state,
              action,
              addAlert,
              packingMode,
              dispatchFns,
              getOrderIdFromBinCode,
              hasStartedPackingProducts
            });
            break;
          default:
            throw new Error(`Invalid state: ${(state as any)?.state}`);
        }
        setState(_state);
      } catch (error: any) {
        addAlert({
          severity: 'error',
          title: 'Invalid event type',
          message: error?.message
        });
      }
    },
    [state, addAlert, packingMode, dispatchFns, getOrderIdFromBinCode, hasStartedPackingProducts]
  );

  return { state, send: dispatch };
}
