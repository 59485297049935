import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { useMemo } from 'react';

type ScanToSpecificBinArgs = {
  binCode: string;
};

type ItemsToScanArgs = {
  itemToScanName: string;
  itemsScanned: number;
  itemsRemainingToPick: number;
};

type ScanPicklistToBinArgs = {
  type: 'picklist';
};

type ScanProductToBinArgs = {
  type: 'product';
} & ScanToSpecificBinArgs &
  ItemsToScanArgs;

type ScanBagsToBinArgs = {
  type: 'bag';
} & ItemsToScanArgs;

type BinScanModalArgs = {
  onClose: () => void;
  openScan: () => void;
} & (ScanPicklistToBinArgs | ScanProductToBinArgs | ScanBagsToBinArgs);

export const BinScanModal = (args: BinScanModalArgs) => {
  const specificBinCopy = useMemo(() => {
    if ('binCode' in args) {
      return `Scan bin ${args.binCode}`;
    }

    return 'Scan a bin to associate with an order';
  }, [args]);

  return (
    <Dialog
      open={true}
      onClose={args.onClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
          minWidth: '280px',
          margin: '24px',
          position: 'relative',
          overflow: 'visible'
        }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '8px',
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          backgroundColor: args.type === 'product' ? args.binCode : 'transparent'
        }}
      />
      <DialogContent
        sx={{
          padding: '32px 20px',
          textAlign: 'center'
        }}
      >
        {(args.type === 'product' || args.type === 'bag') && (
          <>
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
              {args.itemsScanned} / {args.itemsRemainingToPick}
            </Typography>

            <Typography variant="body1" fontWeight={'bold'}>
              {args.itemToScanName} picked
            </Typography>
          </>
        )}

        <Typography variant="body1" sx={{ marginTop: '16px' }}>
          {specificBinCopy}
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '0 20px 20px' }}
      >
        <Button
          onClick={args.openScan}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ fontWeight: 'bold' }}
        >
          Scan
        </Button>
        <Button
          onClick={args.onClose}
          variant="outlined"
          color="error"
          fullWidth
          sx={{ fontWeight: 'bold' }}
        >
          Cancel Scan
        </Button>
      </DialogActions>
    </Dialog>
  );
};
