import { PackingModeArgs } from '../../types';

export const getOrderIdsFromPackingMode = (
  packingMode: PackingModeArgs['packingMode']
): number[] => {
  if (packingMode.type === 'single') {
    return packingMode.packingSession ? [packingMode.packingSession.orderId] : [];
  }
  return Object.values(packingMode.packingSessions || []).map((session) => session.orderId);
};
