import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { PackingSession } from '../../contexts/PackingSessionContext/types';
import { formatPackedProductsForSubmission } from '../../utilities/formatPackedProductsForSubmission';
import { completePackSessionBatchGql } from '../graph/packSession';

export function useCompletePackSessionBatch() {
  const [completePackSessionBatch, { loading, error }] = useMutation(completePackSessionBatchGql);

  const handleCompletePackSessionBatch = useCallback(
    async (packingSessions: PackingSession) => {
      const timestamp = DateTime.now();
      const completionData = Object.values(packingSessions).map((session) => ({
        pack_session_id: session.sessionId,
        timestamp,
        products: formatPackedProductsForSubmission(session.pickingProducts, session.bags)
      }));

      return completePackSessionBatch({
        variables: {
          pack_sessions: completionData
        }
      });
    },
    [completePackSessionBatch, formatPackedProductsForSubmission]
  );

  return { handleCompletePackSessionBatch, loading, error };
}
