/**
 * Performs a natural string comparison between two strings.
 * Natural sorting considers numbers within strings as numeric values rather than character by character.
 * For example: "string2" comes before "string10" in natural sorting.
 *
 * @param {string} a - The first string to compare
 * @param {string} b - The second string to compare
 * @returns {number} Returns:
 *   - -1 if a should come before b
 *   -  1 if a should come after b
 *   -  0 if they are equivalent for sorting purposes
 */
export const naturalSort = (a: string, b: string): number => {
  const parsePart = (s: string) =>
    s
      .split(/([0-9]+)/)
      .filter((part) => part.length > 0)
      .map((part) => (isNaN(Number(part)) ? part : Number(part)));

  const aParts = parsePart(a);
  const bParts = parsePart(b);

  for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
    if (aParts[i] !== bParts[i]) {
      return typeof aParts[i] === typeof bParts[i]
        ? aParts[i] < bParts[i]
          ? -1
          : 1
        : typeof aParts[i] === 'string'
        ? 1
        : -1;
    }
  }

  return aParts.length - bParts.length;
};

/**
 * Performs a natural string comparison between two hyphen-separated strings.
 * First splits strings by hyphens, then performs natural sort on each corresponding part.
 * For example: "B-1-B-1" comes before "B-2-B-1" and "B-2-B-2" comes before "B-10-B-1".
 *
 * @param {string} a - The first hyphen-separated string to compare
 * @param {string} b - The second hyphen-separated string to compare
 * @returns {number} Returns:
 *   - -1 if a should come before b
 *   -  1 if a should come after b
 *   -  0 if they are equivalent for sorting purposes
 */
export const naturalSortHyphenSplit = (a: string, b: string): number => {
  const aParts = a.split('-').filter((part) => part.length > 0);
  const bParts = b.split('-').filter((part) => part.length > 0);

  for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
    const comparison = naturalSort(aParts[i], bParts[i]);
    if (comparison !== 0) {
      return comparison;
    }
  }

  return aParts.length - bParts.length;
};
