import { getTransitionError } from '../../getTransitionError';
import { ActionsFn } from '../../types';
import { isActionType, isState } from '../utils/typeValidators';
import { scanBag } from './scanBag';
import { scanPicklist } from './scanPicklist';
import { scanProduct } from './scanProduct';

export const pickingActions: ActionsFn = (args) => {
  if (!isState(args, 'picking')) {
    throw new Error('Invalid state, not "picking"');
  }

  if (isActionType(args, 'scan_product')) {
    return scanProduct(args);
  } else if (isActionType(args, 'scan_bag')) {
    return scanBag(args);
  } else if (isActionType(args, 'scan_picklist')) {
    return scanPicklist(args);
  }

  throw getTransitionError(args.state, args.action.type);
};
