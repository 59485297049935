import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Modal,
  Typography
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { PackingSession, ProductPackingState } from '../../../contexts/PackingSessionContext/types';

enum ProductCategory {
  AM = 'am',
  PM = 'pm'
}

type UnscannedItemsProps = {
  packingSessions: PackingSession;
  onComplete: () => void;
  onCancel: () => void;
};

type UnscannedItem = {
  productId: number;
  productName: string;
  productQuantity: number;
  isAMProduct: boolean;
};

type UnscannedOrderItems = {
  [ProductCategory.AM]: Record<number, UnscannedItem>;
  [ProductCategory.PM]: Record<number, UnscannedItem>;
};

type ProductListProps = {
  category: ProductCategory;
  products: UnscannedItem[];
};

const ProductList: React.FC<ProductListProps> = ({ category, products }) => {
  if (products.length === 0) return null;

  return (
    <Accordion
      sx={(theme) => ({
        boxShadow: 'none',
        border: `2px solid ${theme.palette.divider}`,
        '&:before': {
          display: 'none'
        },
        '&.Mui-expanded': {
          margin: 0
        }
      })}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle2">
          {category === ProductCategory.AM ? 'AM' : 'PM'} Products ({products.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {products.map((product) => (
          <Typography key={product.productId} variant="subtitle1">
            {product.productQuantity}x {product.productName}
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export const UnscannedItemsPopup: React.FC<UnscannedItemsProps> = ({
  packingSessions,
  onComplete,
  onCancel
}) => {
  const [currentSessionIndex, setCurrentSessionIndex] = useState<number>(0);

  // Filter sessions that need attention (either missing bag scan or have unpacked products)
  const sessionsNeedingAttention = useMemo(() => {
    return Object.entries(packingSessions).filter(([_, session]) => {
      const needsBagScan = session.bags.length === 0;
      const hasUnpackedProducts = Object.values(session.pickingProducts).some(
        (product) => product.state === ProductPackingState.UNPICKED
      );
      return needsBagScan || hasUnpackedProducts;
    });
  }, [packingSessions]);

  const currentSession = useMemo(() => {
    if (currentSessionIndex === null) {
      return null;
    }
    const sessionEntry = sessionsNeedingAttention[currentSessionIndex];
    return sessionEntry ? sessionEntry[1] : null;
  }, [currentSessionIndex, sessionsNeedingAttention]);

  const unscannedOrderItems = useMemo((): UnscannedOrderItems => {
    return Object.values(currentSession?.pickingProducts || {})
      .filter((product) => product.state !== ProductPackingState.PACKED)
      .reduce(
        (acc, product) => {
          const category = product.isAmPacked ? ProductCategory.AM : ProductCategory.PM;
          if (!acc[category]) {
            acc[category] = {};
          }

          acc[category][product.parentProductId] = {
            productId: product.parentProductId,
            productName: product.productName,
            productQuantity: (acc[category][product.parentProductId]?.productQuantity ?? 0) + 1,
            isAMProduct: product.isAmPacked ?? false
          };
          return acc;
        },
        {
          [ProductCategory.AM]: {},
          [ProductCategory.PM]: {}
        } as UnscannedOrderItems
      );
  }, [currentSession]);

  const handleClose = useCallback(() => {
    setCurrentSessionIndex(0);
    onCancel();
  }, [setCurrentSessionIndex, onCancel]);

  const handleNext = useCallback(() => {
    if (currentSessionIndex !== null && currentSessionIndex < sessionsNeedingAttention.length - 1) {
      setCurrentSessionIndex(currentSessionIndex + 1);
    } else {
      handleClose();
      onComplete();
    }
  }, [currentSessionIndex, sessionsNeedingAttention, handleClose, onComplete]);

  const handlePrevious = useCallback(() => {
    if (currentSessionIndex !== null && currentSessionIndex > 0) {
      setCurrentSessionIndex(currentSessionIndex - 1);
    }
  }, [currentSessionIndex, setCurrentSessionIndex]);

  const showPreviousButton = useMemo(
    () => currentSessionIndex > 0 && sessionsNeedingAttention.length > 1,
    [currentSessionIndex, sessionsNeedingAttention]
  );

  return (
    <>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        open={sessionsNeedingAttention.length > 0}
        onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80vw',
            height: '80vh',
            bgcolor: 'background.paper',
            overflow: 'auto',
            p: 2,
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Orders Needing Attention ({currentSessionIndex + 1} of {sessionsNeedingAttention.length}
            )
          </Typography>

          {currentSession && (
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>
              <Typography variant="subtitle1">
                Order ID: {currentSession.orderId} (Bin: {currentSession.binCode})
              </Typography>

              {(Object.values(unscannedOrderItems[ProductCategory.AM]).length > 0 ||
                Object.values(unscannedOrderItems[ProductCategory.PM]).length > 0) && (
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 1, mb: 1 }}>
                  Unscanned items:
                </Typography>
              )}

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <ProductList
                  category={ProductCategory.PM}
                  products={Object.values(unscannedOrderItems[ProductCategory.PM])}
                />
                <ProductList
                  category={ProductCategory.AM}
                  products={Object.values(unscannedOrderItems[ProductCategory.AM])}
                />
              </Box>

              <Box sx={{ flex: 1, display: 'flex', alignItems: 'flex-end', mt: 2 }}>
                {currentSession.bags.length === 0 ? (
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="error"
                    sx={{ mt: 2, marginTop: 'auto', marginLeft: 'auto', marginRight: 'auto' }}>
                    No bags scanned for this order
                  </Typography>
                ) : null}
              </Box>
            </Box>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            {showPreviousButton && (
              <Button onClick={handlePrevious} variant="outlined">
                Previous
              </Button>
            )}
            <Button onClick={handleNext} variant="contained" color="primary">
              {currentSessionIndex < sessionsNeedingAttention.length - 1 ? 'Next' : 'Finish'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
