import { graphql } from '../../gql/gql';

export const startPackSession = graphql(`
  mutation startPackSession(
    $packer_email: String!
    $member_user_id: Int!
    $location_id: String!
    $order_intent_id: Int
    $order_id: Int
    $rl_vendor: RlVendor!
    $timestamp: TimeStamp!
    $is_batch_packing: Boolean
    $batch_pack_session_id: Int
  ) {
    startPackSession(
      packer_email: $packer_email
      member_user_id: $member_user_id
      location_id: $location_id
      order_intent_id: $order_intent_id
      order_id: $order_id
      rl_vendor: $rl_vendor
      timestamp: $timestamp
      is_batch_packing: $is_batch_packing
      batch_pack_session_id: $batch_pack_session_id
    ) {
      id
      packer_email
      member_user_id
      location_id
      order_intent_id
      order_id
      rl_vendor
      started_at
      completed_at
      cancelled_at
      batch_pack_session_id
    }
  }
`);

export const completePackSessionGql = graphql(`
  mutation completePackSession(
    $pack_session_id: Int!
    $timestamp: TimeStamp!
    $products: [ScanSessionProductsInput]!
  ) {
    completePackSession(
      pack_session_id: $pack_session_id
      timestamp: $timestamp
      products: $products
    ) {
      id
      packer_email
      member_user_id
      location_id
      order_intent_id
      rl_vendor
      started_at
      completed_at
      cancelled_at
    }
  }
`);

export const cancelPackSession = graphql(`
  mutation cancelPackSession($pack_session_id: Int!, $timestamp: TimeStamp!) {
    cancelPackSession(pack_session_id: $pack_session_id, timestamp: $timestamp) {
      id
      packer_email
      member_user_id
      location_id
      order_intent_id
      started_at
      completed_at
      cancelled_at
    }
  }
`);

export const cancelPackSessionBatchGql = graphql(`
  mutation cancelPackSessionBatch($pack_session_ids: [Int!]!, $timestamp: TimeStamp!) {
    cancelPackSessionBatch(pack_session_ids: $pack_session_ids, timestamp: $timestamp) {
      id
      packer_email
      member_user_id
      location_id
      order_intent_id
      rl_vendor
      started_at
      completed_at
      cancelled_at
    }
  }
`);

export const completePackSessionBatchGql = graphql(`
  mutation completePackSessionBatch($pack_sessions: [CompletePackSessionBatchInput!]!) {
    completePackSessionBatch(pack_sessions: $pack_sessions) {
      id
      packer_email
      member_user_id
      location_id
      order_intent_id
      rl_vendor
      started_at
      completed_at
      cancelled_at
    }
  }
`);
