import { ActionArgs, PackingModeOptions, State } from '../../types';

export const isState = <T extends State['state'] = State['state']>(
  args: ActionArgs,
  expectedState: T
): args is ActionArgs<T> => {
  return args.state.state === expectedState;
};

export const isActionType = <
  T extends State['state'] = State['state'],
  U extends ActionArgs<T>['action']['type'] = ActionArgs<T>['action']['type'],
  V extends PackingModeOptions['type'] = PackingModeOptions['type']
>(
  action: ActionArgs<T, any, V>,
  expectedActionType: U
): action is ActionArgs<T, U, V> => {
  return action.action.type === expectedActionType;
};

export const isPackingSessionType = <
  T extends State['state'],
  U extends ActionArgs<T>['action']['type'],
  V extends PackingModeOptions['type']
>(
  args: ActionArgs<T>,
  expectedPackingSessionType: V
): args is ActionArgs<T, U, V> => {
  return args.packingMode.type === expectedPackingSessionType;
};
