import { PackedProduct } from '../../../../../../contexts/PackingSessionContext/types';
import { PackingModeArgs } from '../../types';

export const getProductsFromPackingMode = (
  packingMode: PackingModeArgs['packingMode']
): PackedProduct[] => {
  if (packingMode.type === 'single') {
    return Object.values(packingMode.packingSession.pickingProducts);
  }
  return Object.values(packingMode.packingSessions).flatMap((session) =>
    Object.values(session.pickingProducts)
  );
};
