import { useLazyQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useLoginContext } from '../../contexts/LoginContext';
import { OrderPackingState, ProductPackingState } from '../../contexts/PackingSessionContext/types';
import { Picklist } from '../../routes/PickPack/hooks/useDecodeScan';
import { startPackSession } from '../graph/packSession';
import { getPicklistByTopangaUserId } from '../graph/picklist';

type BatchPackingSessionArgs = {
  existingPackingSessionId?: number;
  isBatchPacking: boolean;
};

type CreatePackingSessionArgs = Picklist & BatchPackingSessionArgs;

type UseCreatePackingSessionResponse = {
  createPackingSession: (args: CreatePackingSessionArgs) => Promise<OrderPackingState>;
  loading: boolean;
};

export const useCreatePackingSession = (): UseCreatePackingSessionResponse => {
  const { authUser } = useLoginContext();
  const [loading, setLoading] = useState(false);

  const [getPicklist] = useLazyQuery(getPicklistByTopangaUserId);
  const [startSession] = useMutation(startPackSession);

  const createPackingSession = useCallback(
    async ({
      userId,
      orderId,
      orderIntentId,
      deliveryDate,
      picklist,
      isBatchPacking,
      existingPackingSessionId
    }: CreatePackingSessionArgs): Promise<OrderPackingState> => {
      if (!authUser) {
        throw new Error('Must login to start a packing session.');
      }

      setLoading(true);

      try {
        const { data: sessionData } = await startSession({
          variables: {
            packer_email: authUser.email,
            member_user_id: userId,
            location_id: authUser.nrc.id,
            rl_vendor: authUser.nrc.vendor,
            order_intent_id: orderIntentId,
            order_id: orderId,
            timestamp: DateTime.now(),
            is_batch_packing: isBatchPacking,
            batch_pack_session_id: existingPackingSessionId
          }
        });

        if (!sessionData?.startPackSession) {
          throw new Error('Failed to start pack session');
        }

        const sessionId = sessionData.startPackSession.id;
        const batchPackSessionId = sessionData.startPackSession.batch_pack_session_id;

        const pickingProducts: OrderPackingState['pickingProducts'] = picklist.reduce(
          (acc, product) => {
            acc[product.hashedOrderProductId] = {
              ...product,
              state: ProductPackingState.UNPICKED
            };
            return acc;
          },
          {} as OrderPackingState['pickingProducts']
        );

        return {
          userId,
          orderId,
          orderIntentId,
          deliveryDate,
          sessionId,
          batchPackSessionId: batchPackSessionId!,
          pickingProducts,
          bags: []
        };
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [authUser, getPicklist, startSession]
  );

  return {
    createPackingSession,
    loading
  };
};
