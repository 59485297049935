import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AlertProvider } from './contexts/AlertContext';
import { LoadingProvider } from './contexts/LoadingContext';
import { AuthProvider as AuthProviderV2 } from './contexts/LoginContext';
import { PackingSessionProvider } from './contexts/PackingSessionContext';
import HomeV2 from './routes/Home';
import LoginV2 from './routes/Login';
import PickPackV2 from './routes/PickPack';

const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginV2 />
  },
  {
    path: '/home',
    element: <HomeV2 />
  },
  {
    path: '/pickpack',
    element: <PickPackV2 />
  }
]);

export default function App() {
  return (
    <LoadingProvider>
      <AlertProvider>
        <AuthProviderV2>
          <PackingSessionProvider>
            <RouterProvider router={router} />
          </PackingSessionProvider>
        </AuthProviderV2>
      </AlertProvider>
    </LoadingProvider>
  );
}
