import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useGetPickPackUser } from '../../apis/hooks/useGetPickPackUser';
import { useListRlLocations } from '../../apis/hooks/useListRlLocations';
import { RLNrc } from '../../constants/RLNrc';
import { useLoginContext } from '../../contexts/LoginContext';

export default function Login() {
  const navigate = useNavigate();
  const { setAuthUser, isLoggedIn } = useLoginContext();
  const { locations: nrcs } = useListRlLocations();
  const { fetchUser } = useGetPickPackUser();

  const [email, setEmail] = useState<string | null>(null);
  const [nrc, setNrc] = useState<RLNrc | null>(null);
  const [userNotFoundModalOpen, setUserNotFoundModalOpen] = useState<boolean>(false);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [isLoggedIn]);

  const handleLogin = useCallback(async () => {
    setLoginLoading(true);

    if (!email) {
      setUserNotFoundModalOpen(true);
      setLoginLoading(false);
      return;
    }

    const userResponse = await fetchUser({
      email
    });

    if (!userResponse.success || !nrc) {
      setUserNotFoundModalOpen(true);
      setLoginLoading(false);
      return;
    }

    setAuthUser({ email: userResponse.email, nrc });
    setLoginLoading(false);
  }, [setLoginLoading, fetchUser, setUserNotFoundModalOpen, email, nrc, setAuthUser]);

  return (
    <Stack
      spacing={2}
      p={2}
      sx={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center' }}>
      <TextField
        onChange={(event) => {
          setEmail(event.target.value);
        }}
        inputProps={{
          type: 'email'
        }}
        required
        id="outlined-required"
        label="Email"
        placeholder="hello@therounds.co"
      />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Location</InputLabel>
        <Select
          required
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={nrc || ''}
          label="location"
          onChange={(event: any) => {
            setNrc(event.target.value);
          }}>
          {nrcs.map((nrc) => (
            // @ts-expect-error Sets value and key for onChange event handling
            <MenuItem key={nrc.name} value={nrc}>
              {nrc.name}
            </MenuItem>
          ))}
        </Select>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          disabled={!email || !nrc || loginLoading}
          onClick={handleLogin}>
          Log in
        </Button>
      </FormControl>

      <div className="bottom-content">
        <Typography variant="body2">
          Version: {process.env.REACT_APP_GITHUB_SHA?.substring(0, 7)}
        </Typography>
      </div>

      <Dialog
        open={userNotFoundModalOpen}
        onClose={() => setUserNotFoundModalOpen(false)}
        aria-labelledby="confirm-dialog">
        <DialogTitle>Failed to login</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The provided email address {email} was not recognized or NRC was not selected.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setUserNotFoundModalOpen(false)}
            color="secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
