import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

export type NavOption<T extends string> = {
  label: string;
  icon: React.ReactNode;
  action: T;
};

export default function FixedBottomNavigation<T extends string>({
  onClick,
  navOptions
}: {
  onClick: (value: T) => void;
  navOptions: NavOption<T>[];
}) {
  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} style={{ zIndex: 1200 }}>
      <BottomNavigation
        showLabels
        onChange={(_event, newValue) => {
          onClick(newValue);
        }}
      >
        {navOptions.map((option) => (
          <BottomNavigationAction
            key={option.action}
            value={option.action}
            label={option.label}
            icon={option.icon}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
}
