import { getTransitionError } from '../../getTransitionError';
import { ActionsFn } from '../../types';
import { isActionType, isState } from '../utils/typeValidators';
import { cancel } from './cancel';
import { scanBag } from './scanBag';

export const awaitingColdChainBagActions: ActionsFn = (args) => {
  if (!isState(args, 'awaiting_cold_chain_bag')) {
    throw new Error('Invalid state, not "awaiting_cold_chain_bag"');
  }

  if (isActionType(args, 'scan_bag')) {
    return scanBag(args);
  } else if (isActionType(args, 'cancel')) {
    return cancel(args);
  }

  throw getTransitionError(args.state, args.action.type);
};
