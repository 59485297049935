import { useMutation } from '@apollo/client';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { cancelPackSessionBatchGql } from '../../apis/graph/packSession';
import { useCompletePackSession } from '../../apis/hooks/useCompletePackSession';
import { useCompletePackSessionBatch } from '../../apis/hooks/useCompletePackSessionBatch';
import { BinScanModal } from '../../components/BinScanModal';
import { ColdChainBagScanModal } from '../../components/ColdChainBagScanModal';
import FixedBottomNavigation, { NavOption } from '../../components/FixedBottomNav';
import QrScrene from '../../components/qrScan/QrScrene';
import { useAlertContext } from '../../contexts/AlertContext';
import { useLoadingContext } from '../../contexts/LoadingContext';
import { useLoginContext } from '../../contexts/LoginContext';
import { usePackingSessionContext } from '../../contexts/PackingSessionContext';
import {
  OrderPackingStateWithBin,
  PackingSession
} from '../../contexts/PackingSessionContext/types';
import { useUnloadWarning } from '../../hooks/useUnloadWarning';
import { watchScan } from '../../utilities/zebraScanner';
import { PackingView } from './components/PackingView';
import { getIdForProductCard } from './components/ProductCard/getIdForProductCard';
import { UnscannedItemsPopup } from './components/UnscannedItemsPopup';
import { useDecodeScan } from './hooks/useDecodeScan';
import { usePickingMachine } from './hooks/usePickingMachine';
import { useProcessPickingStateMachineFromScan } from './hooks/useProcessPickingStateMachineFromScan';
import { getProductDisplayList } from './utils/getProductDisplayList';

export type BottomNavAction = 'cancel' | 'pick' | 'scan' | 'complete';

const notScanningNavOptions: NavOption<BottomNavAction>[] = [
  {
    label: 'Exit',
    icon: <ExitToAppIcon />,
    action: 'cancel'
  },
  {
    label: 'Scan',
    icon: <QrCodeScannerIcon />,
    action: 'scan'
  },
  {
    label: 'Complete',
    icon: <DoneOutlineIcon />,
    action: 'complete'
  }
];

const scanningNavOptions: NavOption<BottomNavAction>[] = [
  {
    label: 'Close',
    icon: <CancelIcon />,
    action: 'pick'
  }
];

export default function PickPack() {
  const { safeNavigate } = useUnloadWarning();
  const { isLoggedIn, setAuthUser } = useLoginContext();
  const { setLoading } = useLoadingContext();
  const { addAlert } = useAlertContext();

  const { getDecodedScan, loading: getDecodedScanLoading } = useDecodeScan();
  const { processStateMachineFromScan } = useProcessPickingStateMachineFromScan();
  const {
    dispatchFns,
    packingMode,
    getCountProductRemainingToPackByBinCode,
    getOrderIdFromBinCode,
    subscribe
  } = usePackingSessionContext();
  const { state: pickingState, send: processPickAction } = usePickingMachine();

  const [showUnscannedPopup, setShowUnscannedPopup] = useState<boolean>(false);

  const [scanOpen, setScanOpen] = useState(false);
  const openScan = useCallback(() => setScanOpen(true), [setScanOpen]);
  const closeScan = useCallback(() => setScanOpen(false), [setScanOpen]);

  const [isScanProcessing, setIsScanProcessing] = useState(false);

  const { handleCompletePackSession } = useCompletePackSession();
  const { handleCompletePackSessionBatch } = useCompletePackSessionBatch();
  const [cancelPackSessionBatch] = useMutation(cancelPackSessionBatchGql);

  const logout = useCallback(() => {
    setAuthUser(null);
    safeNavigate('/');
  }, [setAuthUser, safeNavigate]);

  useEffect(() => {
    if (!isLoggedIn) {
      logout();
    }
  }, [isLoggedIn, logout]);

  const packingSessions = useMemo((): PackingSession => {
    if (packingMode.type === 'batch') {
      return packingMode.packingSessions || {};
    }
    return packingMode.packingSession
      ? { [packingMode.packingSession.orderId]: packingMode.packingSession }
      : {};
  }, [packingMode]);

  useEffect(() => {
    setLoading(getDecodedScanLoading || isScanProcessing);
  }, [setLoading, getDecodedScanLoading, isScanProcessing]);

  const pickableProducts = useMemo(() => {
    return Object.values<OrderPackingStateWithBin>(packingSessions).flatMap((session) =>
      Object.values(session.pickingProducts)
        .filter((product) => product.state === 'unpicked' || product.state === 'skipped')
        .map((product) => ({
          parentProductId: product.parentProductId,
          userId: session.userId,
          binCode: session.binCode
        }))
    );
  }, [packingSessions]);

  const packedAssetIds = useMemo((): string[] => {
    const assetIds: string[] = [];

    Object.values<OrderPackingStateWithBin>(packingSessions).forEach((session) => {
      Object.values(session.pickingProducts).forEach((product) => {
        if (product.state === 'packed' && product.assetId) {
          assetIds.push(product.assetId);
        }
      });

      (session.bags || []).forEach((bag) => {
        if (bag.assetId) {
          assetIds.push(bag.assetId);
        }
      });
    });

    return assetIds;
  }, [packingSessions]);

  const handleScan = useCallback(
    async (scanResult: string) => {
      // Prevent concurrent scans
      if (isScanProcessing) {
        return;
      }

      setIsScanProcessing(true);

      try {
        const scanData = await getDecodedScan({ scanResult });

        if (!scanData.success) {
          throw new Error(scanData.message);
        }

        const packingSessionsArr = Object.values(packingSessions);
        const batchPackSessionId =
          packingSessionsArr.length > 0 ? packingSessionsArr[0].batchPackSessionId : undefined;
        await processStateMachineFromScan({
          scan: scanData,
          processPickAction,
          pickableProducts,
          packedAssetIds,
          batchPackSessionId
        });
      } catch (error: any) {
        addAlert({
          severity: 'error',
          title: 'Invalid Scan',
          message: error?.message || 'An error occurred while processing the scan'
        });
      } finally {
        closeScan();
        setIsScanProcessing(false);
      }
    },
    [
      addAlert,
      closeScan,
      processPickAction,
      isScanProcessing,
      packingSessions,
      setIsScanProcessing,
      processStateMachineFromScan
    ]
  );

  useEffect(() => watchScan(handleScan), [handleScan]);

  // Scroll to the product card when it is packed
  useEffect(() => {
    const unsubscribe = subscribe((event) => {
      if (event.event === 'product-packed') {
        const productHashId = event.payload.hashedOrderProductId;
        const documentProductHashId = getIdForProductCard({ hashedOrderProductId: productHashId });
        const productCard = document.getElementById(documentProductHashId);
        if (productCard) {
          productCard.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
    return () => unsubscribe();
  }, [subscribe]);

  const handleSkipProduct = useCallback(
    ({ orderId, hashedOrderProductId }: { orderId: number; hashedOrderProductId: string }) => {
      dispatchFns.skipProduct({ orderId, hashedOrderProductId });
    },
    [dispatchFns]
  );

  const handleUnskipProduct = useCallback(
    ({ orderId, hashedOrderProductId }: { orderId: number; hashedOrderProductId: string }) => {
      dispatchFns.unskipProduct({ orderId, hashedOrderProductId });
    },
    [dispatchFns]
  );

  const handleClearProduct = useCallback(
    ({ orderId, hashedOrderProductId }: { orderId: number; hashedOrderProductId: string }) => {
      dispatchFns.clearProduct({ orderId, hashedOrderProductId });
    },
    [dispatchFns]
  );

  const handleCompletePackingSessions = useCallback(async () => {
    try {
      if (packingMode.type === 'batch') {
        await handleCompletePackSessionBatch(packingSessions);
      } else if (packingMode.type === 'single') {
        await handleCompletePackSession(packingMode.packingSession);
      } else {
        throw new Error('Invalid packing mode');
      }
      dispatchFns.clearPackingSessions();
      addAlert({
        severity: 'success',
        message: 'Packing Sessions Completed'
      });
      safeNavigate('/home');
    } catch (error: any) {
      addAlert({
        severity: 'error',
        title: 'Failed to complete packing sessions',
        message: error?.message
      });
    }
  }, [packingSessions, handleCompletePackSessionBatch, dispatchFns, safeNavigate, addAlert]);

  const handleCancelPackingSessions = useCallback(async () => {
    const sessionIds = Object.values(packingSessions).map((session) => session.sessionId);
    const timestamp = DateTime.now();

    try {
      if (!confirm('Are you sure you want to cancel?')) {
        return;
      }
      await cancelPackSessionBatch({
        variables: {
          pack_session_ids: sessionIds,
          timestamp
        }
      });
      dispatchFns.clearPackingSessions();
      addAlert({
        severity: 'success',
        message: 'Packing Sessions Cancelled'
      });
      safeNavigate('/home');
    } catch (error: any) {
      addAlert({
        severity: 'error',
        title: 'Failed to cancel packing sessions',
        message: error?.message
      });
    }
  }, [packingSessions, cancelPackSessionBatch, dispatchFns, safeNavigate, addAlert]);

  const alertUnscannedItemsBeforeComplete = useCallback(() => {
    const hasSomeUnpickedProducts = Object.values(packingSessions).some((session) =>
      Object.values(session.pickingProducts).some((product) => product.state === 'unpicked')
    );
    // Assume each packing session has one bag
    const hasUnscannedBags = Object.values(packingSessions).some(
      (session) => session.bags.length === 0
    );

    if (hasSomeUnpickedProducts || hasUnscannedBags) {
      setShowUnscannedPopup(true);
    } else {
      handleCompletePackingSessions();
    }
  }, [packingSessions, setShowUnscannedPopup, handleCompletePackingSessions]);

  const handleNavOptionClick = useCallback(
    (navOption: BottomNavAction) => {
      switch (navOption) {
        case 'cancel':
          handleCancelPackingSessions();
          break;
        case 'scan':
          openScan();
          break;
        case 'complete':
          alertUnscannedItemsBeforeComplete();
          break;
      }
    },
    [handleCancelPackingSessions, alertUnscannedItemsBeforeComplete, openScan]
  );

  const closeBinScanAlert = useCallback(() => {
    processPickAction({ type: 'cancel' });
  }, [processPickAction]);

  const amProducts = useMemo(() => {
    return getProductDisplayList(packingSessions, true);
  }, [getProductDisplayList, packingSessions]);

  const pmProducts = useMemo(() => {
    return getProductDisplayList(packingSessions, false);
  }, [getProductDisplayList, packingSessions]);

  const awaitingBinView = useMemo(() => {
    if (pickingState.state === 'awaiting_picklist_bin') {
      return <BinScanModal type="picklist" onClose={closeBinScanAlert} openScan={openScan} />;
    } else if (pickingState.state === 'awaiting_product_bin') {
      const orderId = getOrderIdFromBinCode(pickingState.context.binCode);
      const packingSession = packingSessions[orderId];
      const productName = Object.values(packingSession.pickingProducts).find(
        (product) => product.parentProductId === pickingState.context.parentProductId
      )?.productName;
      return (
        <BinScanModal
          type="product"
          itemToScanName={productName!}
          binCode={pickingState.context.binCode}
          itemsScanned={pickingState.context.pendingProductScans.length}
          itemsRemainingToPick={getCountProductRemainingToPackByBinCode({
            binCode: pickingState.context.binCode,
            parentProductId: pickingState.context.parentProductId
          })}
          onClose={closeBinScanAlert}
          openScan={openScan}
        />
      );
    } else if (pickingState.state === 'awaiting_bag_bin') {
      return (
        <BinScanModal
          type="bag"
          itemToScanName="Bag"
          itemsScanned={pickingState.context.pendingBagScans.length}
          itemsRemainingToPick={pickingState.context.pendingBagScans.length}
          onClose={closeBinScanAlert}
          openScan={openScan}
        />
      );
    } else if (pickingState.state === 'awaiting_cold_chain_bag') {
      return <ColdChainBagScanModal openScan={openScan} onSkip={closeBinScanAlert} />;
    }
    return null;
  }, [
    pickingState.state,
    closeBinScanAlert,
    openScan,
    packingSessions,
    getOrderIdFromBinCode,
    getCountProductRemainingToPackByBinCode
  ]);

  return (
    <>
      {awaitingBinView}
      {scanOpen && <QrScrene onClose={closeScan} onScan={handleScan} />}
      {showUnscannedPopup && (
        <UnscannedItemsPopup
          packingSessions={packingSessions}
          onComplete={handleCompletePackingSessions}
          onCancel={() => setShowUnscannedPopup(false)}
        />
      )}
      <PackingView
        packingSessions={packingSessions}
        amProducts={amProducts}
        pmProducts={pmProducts}
        onRemoveSession={(orderId) => dispatchFns.removePackingSession({ orderId })}
        onSkipProduct={handleSkipProduct}
        onUnskipProduct={handleUnskipProduct}
        onClearProduct={handleClearProduct}
      />
      <FixedBottomNavigation navOptions={notScanningNavOptions} onClick={handleNavOptionClick} />
    </>
  );
}
