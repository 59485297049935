import { ActionArgs, State } from '../../types';

export const scanBin = ({
  action,
  state,
  dispatchFns,
  getOrderIdFromBinCode
}: Pick<
  ActionArgs<'awaiting_bag_bin', 'scan_bin', 'batch'>,
  'action' | 'state' | 'dispatchFns' | 'getOrderIdFromBinCode'
>): State => {
  for (const stateContext of state.context.pendingBagScans) {
    const orderId = getOrderIdFromBinCode(action.binCode);
    dispatchFns.packBag({
      orderId,
      assetId: stateContext.assetId,
      bagType: stateContext.bagType
    });
  }

  return { state: 'picking', context: {} };
};
