import { ActionArgs, State } from '../../types';
import { getOrderIdsFromPackingMode } from '../utils/getOrderIdsFromPackingMode';

export const scanPicklist = ({
  action,
  state,
  addAlert,
  dispatchFns,
  packingMode,
  hasStartedPackingProducts
}: Pick<
  ActionArgs<'picking', 'scan_picklist'>,
  'action' | 'state' | 'addAlert' | 'dispatchFns' | 'packingMode' | 'hasStartedPackingProducts'
>): State => {
  const alreadyScannedPicklists = getOrderIdsFromPackingMode(packingMode);
  if (alreadyScannedPicklists.includes(action.packingSession.orderId)) {
    addAlert({
      severity: 'error',
      title: 'Picklist already scanned',
      message: 'Please scan a different picklist.'
    });
    return state;
  }

  if (packingMode.type === 'single') {
    if (packingMode.packingSession) {
      addAlert({
        severity: 'error',
        title: 'Product packing has already started',
        message: 'Switch to batch packing to scan a multiple picklists.'
      });
      return {
        state: state.state,
        context: {}
      };
    }

    dispatchFns.addPackingSession(action.packingSession, 'black');
    return {
      state: state.state,
      context: {}
    };
  } else if (packingMode.type === 'batch') {
    if (hasStartedPackingProducts) {
      addAlert({
        severity: 'error',
        title: 'Product packing has already started',
        message: 'Please scan a different product.'
      });
      return state;
    }
    return {
      state: 'awaiting_picklist_bin',
      context: {
        packingSession: action.packingSession
      }
    };
  }
  return state;
};
