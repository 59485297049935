import CloseIcon from '@mui/icons-material/Close';
import { AlertTitle, IconButton } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

export interface Alert {
  severity: AlertProps['severity'];
  title?: AlertProps['title'];
  message: string;
}

interface AlertContextValue {
  alert: Alert | null;
  addAlert: (alert: Alert) => void;
  removeAlert: () => void;
}

const AlertContext = createContext<AlertContextValue>({
  alert: null,
  addAlert: () => {},
  removeAlert: () => {}
});
export const useAlertContext = () => useContext(AlertContext);

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [alert, setAlert] = useState<Alert | null>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const clearAndRemoveAlert = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
    setAlert(null);
  }, [timeoutIdRef, setAlert]);

  const addAlert = useCallback(
    (newAlert: Alert) => {
      clearAndRemoveAlert();
      setAlert(newAlert);
      timeoutIdRef.current = setTimeout(
        clearAndRemoveAlert,
        newAlert.severity === 'success' ? 2000 : 4000
      );
    },
    [setAlert, timeoutIdRef, clearAndRemoveAlert]
  );

  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  return (
    <AlertContext.Provider value={{ alert, addAlert, removeAlert: clearAndRemoveAlert }}>
      {!!alert && (
        <MuiAlert
          severity={alert.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={clearAndRemoveAlert}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{
            position: 'fixed',
            top: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 10000,
            minWidth: '300px',
            maxWidth: '90%',
            boxShadow: 3
          }}
        >
          {!!alert.title && <AlertTitle>{alert.title}</AlertTitle>}
          {alert.message}
        </MuiAlert>
      )}
      {children}
    </AlertContext.Provider>
  );
};
