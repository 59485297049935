import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { OrderPackingStateWithBin } from '../../contexts/PackingSessionContext/types';
import { formatPackedProductsForSubmission } from '../../utilities/formatPackedProductsForSubmission';
import { completePackSessionGql } from '../graph/packSession';

export function useCompletePackSession() {
  const [completePackSession, { loading, error }] = useMutation(completePackSessionGql);

  const handleCompletePackSession = useCallback(
    async (packingSession: OrderPackingStateWithBin) => {
      const timestamp = DateTime.now();
      const completionData = {
        pack_session_id: packingSession.sessionId,
        timestamp,
        products: formatPackedProductsForSubmission(
          packingSession.pickingProducts,
          packingSession.bags
        )
      };

      return completePackSession({
        variables: {
          pack_session_id: completionData.pack_session_id,
          timestamp: completionData.timestamp,
          products: completionData.products
        }
      });
    },
    [completePackSession]
  );

  return { handleCompletePackSession, loading, error };
}
