import { noop } from 'lodash';
import { DateTime } from 'luxon';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useAlertContext } from '../AlertContext';
import {
  AddPackingSessionFn,
  ClearPackingSessionsFn,
  ClearProductFn,
  DispatchFns,
  EventCallback,
  OrderPackingStateWithBin,
  PackBagFn,
  PackingModeArgs,
  PackingSession,
  PackingSessionContextValue,
  PackProductFn,
  PickingSessionType,
  ProductPackingState,
  RemovePackingSessionFn,
  SkipProductFn,
  UnskipProductFn
} from './types';
import {
  deletePackingSession,
  updateProductStateByHashedOrderProductId,
  updateProductStateByParentProductId
} from './utils';

const PackingSessionContext = createContext<PackingSessionContextValue>({
  packingSessionType: 'single',
  setPickingSessionType: () => {},
  packingMode: {
    type: 'single',
    packingSession: {} as OrderPackingStateWithBin
  },
  isBatchPacking: false,
  dispatchFns: {} as DispatchFns,
  getOrderIdFromBinCode: () => -1,
  getCountProductRemainingToPackByBinCode: () => 0,
  subscribe: () => () => {},
  hasStartedPackingProducts: false
});

export const usePackingSessionContext = () => useContext(PackingSessionContext);

const clearPackingSessions: ClearPackingSessionsFn = (): PackingSession => ({});

const addPackingSession: AddPackingSessionFn = ({
  state,
  packSession,
  binCode
}): PackingSession => ({
  ...state,
  [packSession.orderId]: { ...packSession, binCode }
});

const skipProduct: SkipProductFn = ({ state, orderId, hashedOrderProductId }) =>
  updateProductStateByHashedOrderProductId(state, orderId, hashedOrderProductId, {
    state: ProductPackingState.SKIPPED,
    timestamp: DateTime.now()
  });

const unskipProduct: UnskipProductFn = ({ state, orderId, hashedOrderProductId }) =>
  updateProductStateByHashedOrderProductId(state, orderId, hashedOrderProductId, {
    state: ProductPackingState.UNPICKED
  });

const packProduct: PackProductFn = ({ state, orderId, parentProductId, assetId }) =>
  updateProductStateByParentProductId(state, orderId, parentProductId, {
    state: ProductPackingState.PACKED,
    assetId,
    timestamp: DateTime.now()
  });

const packBag: PackBagFn = ({ state, orderId, assetId, bagType }) => ({
  ...state,
  [orderId]: {
    ...state[orderId],
    bags: [...state[orderId].bags, { assetId, bagType }]
  }
});

const clearProduct: ClearProductFn = ({ state, orderId, hashedOrderProductId }) =>
  updateProductStateByHashedOrderProductId(state, orderId, hashedOrderProductId, {
    state: ProductPackingState.UNPICKED
  });

const removePackingSession: RemovePackingSessionFn = ({ state, orderId }) => {
  if (Object.keys(state).length === 1) {
    throw new Error('Cannot remove last packing session. Use the exit button to exit the app.');
  }
  return deletePackingSession(state, orderId);
};

const noopFn = () => noop;

export const PackingSessionProvider = ({ children }: { children: ReactNode }) => {
  const { addAlert } = useAlertContext();
  const [packingSessions, setPackingSessions] = useState<PackingSession>({});
  const [packingSessionType, setPackingSessionType] = useState<PickingSessionType>('single');
  const [listener, setListener] = useState<EventCallback>(noopFn);

  const subscribe = useCallback((callback: EventCallback) => {
    // Replace any existing listener
    setListener(() => callback);

    // Return an unsubscribe function
    return () => setListener(noopFn);
  }, []);

  const isBatchPacking = useMemo(() => Object.keys(packingSessions).length > 1, [packingSessions]);

  const getOrderIdFromBinCode = useCallback(
    (binCode: string): number => {
      if (!binCode && isBatchPacking) {
        throw new Error('No bin code provided');
      } else if (!binCode && !isBatchPacking) {
        return Number(Object.keys(packingSessions)[0]);
      }

      const order = Object.values(packingSessions).find((o) => o.binCode === binCode);
      if (!order) {
        throw new Error(`No order found for bin code: ${binCode}`);
      }
      return order.orderId;
    },
    [packingSessions, isBatchPacking]
  );

  const getCountProductRemainingToPackByBinCode = useCallback(
    ({ binCode, parentProductId }: { binCode: string; parentProductId: number }) => {
      const orderId = getOrderIdFromBinCode(binCode);
      const products = packingSessions[orderId]?.pickingProducts || {};
      return Object.values(products).filter(
        (product) =>
          product.state === ProductPackingState.UNPICKED &&
          product.parentProductId === parentProductId
      ).length;
    },
    [packingSessions, getOrderIdFromBinCode]
  );

  const packingMode = useMemo((): PackingModeArgs => {
    if (packingSessionType === 'single') {
      return {
        type: packingSessionType,
        packingSession: Object.values(packingSessions)[0]
      };
    } else if (packingSessionType === 'batch') {
      return {
        type: 'batch',
        packingSessions
      };
    }
    throw new Error('Invalid packing session type');
  }, [packingSessionType, packingSessions]);

  const hasStartedPackingProducts = useMemo(() => {
    return Object.values(packingSessions).some((session) =>
      Object.values(session.pickingProducts).some(
        (product) => product.state === ProductPackingState.PACKED
      )
    );
  }, [packingSessions]);

  const protectedSetPackingSessions = useCallback(
    (fn: (prev: PackingSession) => PackingSession) => {
      setPackingSessions((prev) => {
        try {
          return fn(prev);
        } catch (error: any) {
          addAlert({
            severity: 'error',
            title: 'Error updating packing sessions',
            message: error?.message
          });
          return prev;
        }
      });
    },
    [setPackingSessions, addAlert]
  );

  const dispatchFns: DispatchFns = useMemo(() => {
    return {
      clearPackingSessions: () => protectedSetPackingSessions(() => clearPackingSessions()),
      addPackingSession: (packSession, binCode) => {
        protectedSetPackingSessions((_packingSessions) => {
          const response = addPackingSession({ state: _packingSessions, packSession, binCode });
          addAlert({
            severity: 'success',
            message: 'Packing Session Added'
          });
          return response;
        });
      },
      skipProduct: ({ orderId, hashedOrderProductId }) => {
        protectedSetPackingSessions((_packingSessions) =>
          skipProduct({ state: _packingSessions, orderId, hashedOrderProductId })
        );
      },
      unskipProduct: ({ orderId, hashedOrderProductId }) => {
        protectedSetPackingSessions((_packingSessions) =>
          unskipProduct({ state: _packingSessions, orderId, hashedOrderProductId })
        );
      },
      packProduct: ({ orderId, parentProductId, assetId }) => {
        protectedSetPackingSessions((_packingSessions) => {
          const { packSessions, affectedProduct } = packProduct({
            state: _packingSessions,
            orderId,
            parentProductId,
            assetId
          });
          listener({
            event: 'product-packed',
            payload: {
              hashedOrderProductId: affectedProduct.hashedOrderProductId
            }
          });
          addAlert({
            severity: 'success',
            message: 'Product Packed'
          });
          return packSessions;
        });
      },
      packBag: ({ orderId, assetId, bagType }) => {
        protectedSetPackingSessions((_packingSessions) => {
          const response = packBag({ state: _packingSessions, orderId, assetId, bagType });
          addAlert({
            severity: 'success',
            message: 'Bag Packed'
          });
          return response;
        });
      },
      clearProduct: ({ orderId, hashedOrderProductId }) => {
        protectedSetPackingSessions((_packingSessions) =>
          clearProduct({ state: _packingSessions, orderId, hashedOrderProductId })
        );
      },
      removePackingSession: ({ orderId }) => {
        protectedSetPackingSessions((_packingSessions) =>
          removePackingSession({ state: _packingSessions, orderId })
        );
      }
    };
  }, [
    packingSessions,
    getOrderIdFromBinCode,
    protectedSetPackingSessions,
    isBatchPacking,
    clearPackingSessions,
    addPackingSession,
    skipProduct,
    unskipProduct,
    packProduct,
    packBag,
    clearProduct,
    listener
  ]);

  return (
    <PackingSessionContext.Provider
      value={{
        packingSessionType,
        setPickingSessionType: setPackingSessionType,
        packingMode,
        dispatchFns,
        isBatchPacking,
        getOrderIdFromBinCode,
        getCountProductRemainingToPackByBinCode,
        subscribe,
        hasStartedPackingProducts
      }}
    >
      {children}
    </PackingSessionContext.Provider>
  );
};
