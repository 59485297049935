import {
  PackedProduct,
  PackingSession,
  ProductPackingState,
  ProductPackingStateObj
} from './types';

type UpdateProductStateByParentProductIdResponse = {
  packSessions: PackingSession;
  affectedProduct: PackedProduct;
};

/**
 * Updates the state of a product in a packing session using its parent product ID.
 * @param {PackingSession} state - The current packing session state
 * @param {number} orderId - The ID of the order containing the product
 * @param {number} parentProductId - The parent product ID to find the product
 * @param {ProductPackingStateObj} newState - The new state to apply to the product
 * @returns {PackingSession} Updated packing session state
 * @throws {Error} If order or product is not found
 */
export const updateProductStateByParentProductId = (
  state: PackingSession,
  orderId: number,
  parentProductId: number,
  newState: ProductPackingStateObj
): UpdateProductStateByParentProductIdResponse => {
  const session = state[orderId];
  if (!session) {
    throw new Error(`Order ${orderId} not found`);
  }

  const product = Object.values(session.pickingProducts).find(
    (p) => p.parentProductId === parentProductId && [ProductPackingState.UNPICKED].includes(p.state)
  );
  if (!product) {
    throw new Error(`Product with parent ID ${parentProductId} not found in order ${orderId}`);
  }

  return {
    packSessions: {
      ...state,
      [orderId]: {
        ...session,
        pickingProducts: {
          ...session.pickingProducts,
          [product.hashedOrderProductId]: { ...product, ...newState }
        }
      }
    },
    affectedProduct: product
  };
};

/**
 * Updates the state of a product in a packing session using its hashed order product ID.
 * @param {PackingSession} state - The current packing session state
 * @param {number} orderId - The ID of the order containing the product
 * @param {string} hashedOrderProductId - The hashed order product ID to find the product
 * @param {ProductPackingStateObj} newState - The new state to apply to the product
 * @returns {PackingSession} Updated packing session state
 * @throws {Error} If order or product is not found
 */
export const updateProductStateByHashedOrderProductId = (
  state: PackingSession,
  orderId: number,
  hashedOrderProductId: string,
  newState: ProductPackingStateObj
): PackingSession => {
  const session = state[orderId];
  if (!session) {
    throw new Error(`Order ${orderId} not found`);
  }

  const product = session.pickingProducts[hashedOrderProductId];
  if (!product) {
    throw new Error(`Product ${hashedOrderProductId} not found in order ${orderId}`);
  }

  return {
    ...state,
    [orderId]: {
      ...session,
      pickingProducts: {
        ...session.pickingProducts,
        [hashedOrderProductId]: { ...product, ...newState }
      }
    }
  };
};

/**
 * Removes a packing session for a specific order.
 * @param {PackingSession} state - The current packing session state
 * @param {number} orderId - The ID of the order to remove
 * @returns {PackingSession} Updated packing session state with the specified order removed
 */
export const deletePackingSession = (state: PackingSession, orderId: number): PackingSession => {
  const newState = { ...state };
  delete newState[orderId];
  return newState;
};
