import { getTransitionError } from '../../getTransitionError';
import { ActionArgs, State } from '../../types';
import { getBagsFromPackingMode } from '../utils/getBagsFromPackingMode';
import { isAlreadyScannedAsset } from '../utils/isAlreadyScannedAsset';

export const scanBag = ({
  action,
  state,
  addAlert,
  packingMode,
  dispatchFns
}: Pick<
  ActionArgs<'awaiting_cold_chain_bag', 'scan_bag'>,
  'action' | 'state' | 'addAlert' | 'packingMode' | 'dispatchFns'
>): State => {
  const alreadyScannedBags = getBagsFromPackingMode(packingMode);
  if (
    isAlreadyScannedAsset({
      alreadyScannedAssets: alreadyScannedBags,
      scannedAsset: action.assetId
    })
  ) {
    addAlert({
      severity: 'error',
      title: 'Bag already scanned',
      message: 'Please scan a different bag.'
    });
    return state;
  }

  if (action.bagType !== 'cold') {
    addAlert({
      severity: 'error',
      title: 'Invalid bag type',
      message: 'Please scan a cold chain bag.'
    });
    return state;
  }

  if (packingMode.type === 'single') {
    dispatchFns.packBag({
      orderId: packingMode.packingSession.orderId,
      assetId: action.assetId,
      bagType: action.bagType
    });
    return {
      state: 'picking',
      context: {}
    };
  } else if (packingMode.type === 'batch') {
    return {
      state: 'awaiting_bag_bin',
      context: {
        pendingBagScans: [{ assetId: action.assetId, bagType: action.bagType }]
      }
    };
  }

  throw getTransitionError(state, action.type);
};
