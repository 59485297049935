import { graphql } from '../../gql/gql';

export const getPicklistByTopangaUserId = graphql(`
  query getPicklistByTopangaUserId($order_id: Int) {
    getPicklistByTopangaUserIdHash(order_id: $order_id) {
      picklist {
        product_id
        product_name
        product_quantity
        warehouse_location
        is_am_packed
        is_oos
      }
      user_id
      order_intent_id
      order_id
      delivery_date
    }
  }
`);

export const getProductFromPickScan = graphql(`
  query getProductFromPickScan($decoded_scan_value: String!, $user_id: Int!) {
    getProductFromPickScan(decoded_scan_value: $decoded_scan_value, user_id: $user_id) {
      ... on BagScanType {
        parsed_scan_value
      }
      ... on ProductScanType {
        parsed_scan_value
        sellable_entity_variation_id
        scanned_code_type
      }
    }
  }
`);

export const resolvePickScanGql = graphql(`
  query resolvePickScan($decoded_scan_value: String!) {
    resolvePickScan(decoded_scan_value: $decoded_scan_value) {
      ... on BagScanType {
        parsed_scan_value
        bag_type
      }
      ... on ProductScanTypeV2 {
        parsed_scan_value
        parent_product_id
        scanned_code_type
      }
      ... on BinScanType {
        parsed_scan_value
        bin_code
      }
      ... on PicklistScanType {
        parsed_scan_value
        picklist {
          picklist {
            parent_product_id
            product_id
            hashed_order_product_id
            product_name
            warehouse_location
            is_am_packed
            is_oos
            backstock_location
            prepped_inventory_quantity
            pick_sort_order
            does_require_cold_chain_bag
          }
          user_id
          order_intent_id
          order_id
          delivery_date
        }
      }
    }
  }
`);
