import { Button, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreatePackingSession } from '../../apis/hooks/useCreatePackingSession';
import { BinScanModal } from '../../components/BinScanModal';
import QrScrene from '../../components/qrScan';
import { useAlertContext } from '../../contexts/AlertContext';
import { useLoadingContext } from '../../contexts/LoadingContext';
import { useLoginContext } from '../../contexts/LoginContext';
import { usePackingSessionContext } from '../../contexts/PackingSessionContext';
import { PickingSessionType } from '../../contexts/PackingSessionContext/types';
import { watchScan } from '../../utilities/zebraScanner';
import { useDecodeScan } from '../PickPack/hooks/useDecodeScan';
import { usePickingMachine } from '../PickPack/hooks/usePickingMachine';

export default function Home() {
  const navigate = useNavigate();
  const { isLoggedIn, setAuthUser } = useLoginContext();
  const { setLoading } = useLoadingContext();
  const { addAlert } = useAlertContext();

  const { getDecodedScan, loading: getDecodedScanLoading } = useDecodeScan();
  const { createPackingSession, loading: createPackingSessionLoading } = useCreatePackingSession();
  const {
    packingMode,
    packingSessionType,
    setPickingSessionType: setPackingSessionType
  } = usePackingSessionContext();
  const { state: pickingState, send: processPickAction } = usePickingMachine();

  const [scanOpen, setScanOpen] = useState(false);
  const openScan = useCallback(() => setScanOpen(true), [setScanOpen]);
  const closeScan = useCallback(() => setScanOpen(false), [setScanOpen]);

  const [isScanProcessing, setIsScanProcessing] = useState(false);

  const logout = useCallback(() => {
    setAuthUser(null);
    navigate('/');
  }, [setAuthUser, navigate]);

  useEffect(() => {
    if (!isLoggedIn) {
      logout();
    }
  }, [isLoggedIn, logout]);

  const hasScannedPicklist = useMemo(() => {
    if (packingMode.type === 'batch') {
      return Object.values(packingMode.packingSessions)?.length > 0;
    } else if (packingMode.type === 'single') {
      return !!packingMode.packingSession;
    }
    return false;
  }, [packingMode]);

  useEffect(() => {
    if (hasScannedPicklist) {
      navigate('/pickpack');
    }
  }, [hasScannedPicklist, navigate]);

  useEffect(() => {
    setLoading(createPackingSessionLoading || getDecodedScanLoading || isScanProcessing);
  }, [setLoading, createPackingSessionLoading, getDecodedScanLoading, isScanProcessing]);

  const handleScan = useCallback(
    async (scanResult: string) => {
      if (isScanProcessing) {
        return;
      }

      setIsScanProcessing(true);

      try {
        const scanData = await getDecodedScan({ scanResult });

        if (!scanData.success) {
          throw new Error(scanData.message);
        }

        if (scanData.type === 'picklist') {
          processPickAction({
            type: 'scan_picklist',
            packingSession: await createPackingSession({
              ...scanData.picklist,
              isBatchPacking: packingSessionType === 'batch'
            })
          });
        } else if (scanData.type === 'bin') {
          processPickAction({
            type: 'scan_bin',
            binCode: scanData.binCode
          });
        } else {
          addAlert({
            severity: 'error',
            title: 'Invalid Picklist Scan',
            message: 'Scan is not recognized as a valid Picklist QR code'
          });
        }

        closeScan();
      } catch (error) {
        addAlert({
          severity: 'error',
          title: 'Error',
          message: 'An error occurred while processing the scan'
        });
      } finally {
        setIsScanProcessing(false);
      }
    },
    [
      addAlert,
      closeScan,
      getDecodedScan,
      createPackingSession,
      processPickAction,
      isScanProcessing,
      setIsScanProcessing
    ]
  );

  useEffect(() => watchScan(handleScan), [handleScan, watchScan]);

  const closeBinScanAlert = useCallback(() => {
    processPickAction({ type: 'cancel' });
  }, [processPickAction]);

  const awaitingPicklistBinScan = useMemo(() => {
    if (pickingState.state === 'awaiting_picklist_bin') {
      return <BinScanModal onClose={closeBinScanAlert} type="picklist" openScan={openScan} />;
    }
    return null;
  }, [pickingState, closeBinScanAlert]);

  const handlePackingSessionTypeChange = useCallback(
    (newPackingSessionType: PickingSessionType) => {
      setPackingSessionType(newPackingSessionType);
    },
    [setPackingSessionType]
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'flex-end'
      }}>
      {awaitingPicklistBinScan}

      <Stack p={2} spacing={2}>
        {!scanOpen && (
          <Button variant="contained" onClick={openScan}>
            Scan Picklist
          </Button>
        )}

        <Button variant="outlined" color="error" onClick={logout}>
          Log Out
        </Button>

        <ToggleButtonGroup
          sx={{ justifyContent: 'center' }}
          color="primary"
          value={packingSessionType}
          exclusive
          onChange={(_, newPackingSessionType) =>
            handlePackingSessionTypeChange(newPackingSessionType)
          }>
          <ToggleButton value="single">Single</ToggleButton>
          <ToggleButton value="batch">Batch</ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      {scanOpen && <QrScrene onClose={closeScan} onScan={handleScan} />}
    </div>
  );
}
