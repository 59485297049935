import { OrderPackingStateWithBin } from '../../../../../../contexts/PackingSessionContext/types';

type DoesOrderRequireColdChainBagScanArgs = {
  parentProductId: number;
  packingSession: OrderPackingStateWithBin;
};

export const doesOrderRequireColdChainBagScan = ({
  parentProductId,
  packingSession
}: DoesOrderRequireColdChainBagScanArgs) => {
  const doesRequireColdChainBag = Object.values(packingSession.pickingProducts).find(
    (p) => p.parentProductId === parentProductId
  )?.doesRequireColdChainBag;
  const hasColdChainBagScanned = packingSession.bags.some((b) => b.bagType === 'cold');
  return doesRequireColdChainBag && !hasColdChainBagScanned;
};
