import { DateTime } from 'luxon';
import { OrderPackingState, ProductPackingState } from '../contexts/PackingSessionContext/types';
import { ScanSessionProductStatus } from '../gql/graphql';

export const formatPackedProductsForSubmission = (
  packingSessionProducts: OrderPackingState['pickingProducts'],
  bags: OrderPackingState['bags']
) => [
  ...Object.values(packingSessionProducts).map((product) => ({
    product_id: product.sellableEntityVariationId,
    status:
      product.state === ProductPackingState.PACKED
        ? ScanSessionProductStatus.Scanned
        : ScanSessionProductStatus.Skipped,
    asset_id: product.state === ProductPackingState.PACKED ? product.assetId : undefined,
    timestamp:
      product.state === ProductPackingState.PACKED || product.state === ProductPackingState.SKIPPED
        ? product.timestamp
        : DateTime.now()
  })),
  ...bags.map((bag) => ({
    status: ScanSessionProductStatus.Scanned,
    asset_id: bag.assetId,
    timestamp: DateTime.now()
  }))
];
